import axios from 'axios';
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";

export const fetchResources = () => {
  return axios.get('/coach/assets/my');
};

export const fetchResourceById = (resourceId) => {
  return axios.get(`/coach/assets/${resourceId}`);
};

export const updateResourceById = ({
  id,
  type,
  description,
  mediaId = null,
  name = "",
  externalLink = null,
  sortOrder = 1,
}) => {
  return axiosWithSnackbar.patch(`/coach/assets/${id}`, {
    type,
    description,
    name,
    media_id: mediaId,
    external_link: externalLink || null,
    sort_order: sortOrder,
  });
};

export const createResource = ({
  type,
  description,
  mediaId = null,
  name = "",
  externalLink = null,
  sortOrder = 1,
}) => {
  return axiosWithSnackbar.post('/coach/assets', {
    type,
    description,
    name,
    media_id: mediaId,
    external_link: externalLink || null,
    sort_order: sortOrder,
  });
};
