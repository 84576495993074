import {string} from "yup";
const contactSupportFormFields = [
  {
    name: "name",
    label: "Name",
    validation: string().required("Name is required"),
  },
  {
    name: "email",
    label: "Email",
    validation: string().required("Email is required"),
  },
  {
    name: "company",
    label: "Company",
  },
  {
    name: "info",
    label: "Further Info",
    type: "textarea",
  },

  // {
  //   name: 'interests',
  //   type: 'checkbox',
  //   options: [
  //     { label: 'Music', value: 'music' },
  //     { label: 'Art', value: 'art' },
  //     // Add more options as needed
  //   ],
  //   initialValue: [], // Since this is a checkbox group, start with an empty array
  // }
  // Add more fields as needed
];

export default contactSupportFormFields;
