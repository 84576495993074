import axios from "axios";
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";
import { getBaseHeaders } from "src/helpers/utils";

export const loginApi = async (todo) => {
  return await axiosWithSnackbar.post("/token", todo);
};

export const coachLoginApi = async (todo) => {
  return await axiosWithSnackbar.post("/coach/token", todo);
};

export const coachSignUpApi = async (body) => {
  return await axiosWithSnackbar.post("/coach/register", body);
};

export const resetPasswordApi = async (todo) => {
  return await axiosWithSnackbar.post("/password/email", todo);
};

export const paswordResetApi = (credentials) => {
  return axiosWithSnackbar.post("/password/reset", credentials);
};

export const googleSignOnApi = async () => {
  return await axios.get("/login/google");
};

export const facebookSignOnApi = async () => {
  return await axios.get("/login/facebook");
};

export const microsoftSignOnApi = async () => {
  return await axios.get("/login/graph");
};

export const providerSignOnCallbackApi = async (todo) => {
  const url = `/login/${todo.provider}/callback?${todo.search}`;
  return await axios.get(url, {
    headers: { "x-subdomain": todo.subdomain },
  });
};

export const getCurrentUserDetailsApi = async () => {
  return await axios.get("/users/my");
};

export const fetchUserApi = async () => {
  const endpoint = `/users/my?include=account`;
  return await axios.get(endpoint);
};

export const updateUserApi = async (id, updateUser) => {
  return fetch(`/users/${id}`, {
    method: "PATCH",
    body: JSON.stringify(updateUser),
    headers: getBaseHeaders(),
  });
};

export const getCurrentCoachDetailsApi = async () => {
  return await axios.get("/coach/my");
};

export const getCurrentUserDepartmentsApi = () => {
  return axios.get("/departments/my");
};

export const departments = ({ limit = 1000 } = {}) => {
  return axios.get("/departments");
};
export const getCurrentUserAccountApi = () => {
  return axios.get("/accounts/my").then((res) => res);
};
export const inviteUserApi = async (todo) => {
  return await axiosWithSnackbar.post("/users/invite", todo);
};

export const updateCurrentUserProfileApi = async (todo, id) => {
  return await axiosWithSnackbar.patch(`/users/${id}`, todo);
};

export const updateCurrentCompanyAdminApi = async (todo, id) => {
  return await axiosWithSnackbar.patch(`/accounts/${id}`, todo);
};

export const updateCurrentCoachProfileApi = async (todo) => {
  return await axiosWithSnackbar.patch("/coach/my", todo);
};
