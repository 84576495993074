import { useEffect, useState } from "react";
import useCoacheDashboardHook from "src/app/views/hooks/coachHooks/useCoachDashboardHook";
import useCompanyDashboardHook from "src/app/views/hooks/companyHooks/useCompanyDashboardHook";
import useEmployeeDashboardHook from "src/app/views/hooks/employeeHooks/useEmployeeDashboardHook";

/* 
    "Company Manager",
    "Company Admin",
    "Department Manager",
    "Department Admin",
    "Employee",
    "coaches",
*/
const useHeaderHook = ({ userType }) => {
  const companyDashboardHook = useCompanyDashboardHook();
  const employeeDashboardHook = useEmployeeDashboardHook();
  const coacheDashboardHook = useCoacheDashboardHook();

  const [values, setValues] = useState(null);

  useEffect(() => {
    if (userType === "Company Admin") {
      setValues({
        getHeaderImage: companyDashboardHook.getHeaderImage,
        getHeaderCoins: companyDashboardHook.getHeaderCoins,
        toTokenView: companyDashboardHook.toTokenView,
        getProfileRoute: companyDashboardHook.getProfileRoute,
        getSearchRoute: companyDashboardHook.getSearchRoute,
      });
    }

    if (userType === "Employee") {
      setValues({
        getHeaderImage: employeeDashboardHook.getHeaderImage,
        getHeaderCoins: employeeDashboardHook.getHeaderCoins,
        toTokenView: employeeDashboardHook.toTokenView,
        getProfileRoute: employeeDashboardHook.getProfileRoute,
        getSearchRoute: employeeDashboardHook.getSearchRoute,
      });
    }

    if (userType === "coaches") {
      setValues({
        getHeaderImage: coacheDashboardHook.getHeaderImage,
        getHeaderCoins: coacheDashboardHook.getHeaderCoins,
        toTokenView: coacheDashboardHook.toTokenView,
        getProfileRoute: coacheDashboardHook.getProfileRoute,
        getSearchRoute: coacheDashboardHook.getSearchRoute,
      });
    }
  }, [
    coacheDashboardHook.getHeaderCoins,
    coacheDashboardHook.getHeaderImage,
    coacheDashboardHook.getProfileRoute,
    coacheDashboardHook.getSearchRoute,
    coacheDashboardHook.toTokenView,
    companyDashboardHook.getHeaderCoins,
    companyDashboardHook.getHeaderImage,
    companyDashboardHook.getProfileRoute,
    companyDashboardHook.getSearchRoute,
    companyDashboardHook.toTokenView,
    employeeDashboardHook.getHeaderCoins,
    employeeDashboardHook.getHeaderImage,
    employeeDashboardHook.getProfileRoute,
    employeeDashboardHook.getSearchRoute,
    employeeDashboardHook.toTokenView,
    userType,
  ]);

  return {
    values,
  };
};

export default useHeaderHook;
