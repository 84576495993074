import { useFormik } from 'formik';
import * as yup from 'yup';

const useFormFields = (fields,handleSubmit) => {
  const initialValues = fields.reduce((values, field) => {
    values[field.name] = field.initialValue || '';
    return values;
  }, {});

  const validationSchema = yup.object(
    fields.reduce((schema, field) => {
      if (field.validation) {
        schema[field.name] = field.validation;
      }
      return schema;
    }, {})
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });


  return formik;
};

export default useFormFields