import axios from 'axios';
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";
import { getBaseSearchParams } from "src/helpers/utils";

export const fetchAppointmentTypes = (searchParams = getBaseSearchParams()) => {
  return axios.get(`/coach/appointment-types?${searchParams.toString()}`);
};

export const createAppointmentType = ({
  name,
  sessionLength,
  sessionRate,
  sortOrder = 1,
  description = null,
  bookingMessage = null,
  imageLocation = null,
  allowableModes = [],
}) => {
  return axiosWithSnackbar.post(`/coach/appointment-types`, {
    allowable_modes: JSON.stringify(allowableModes),
    name,
    image_location: imageLocation,
    description,
    booking_message: bookingMessage,
    session_length: sessionLength,
    session_rate: sessionRate,
    sort_order: sortOrder,
  });
};

export const fetchAppointmentTypeById = (appointmentTypeId) => {
  return axios.get(`/coach/appointment-types/${appointmentTypeId}`);
};

export const deleteAppointmentType = (appointmentTypeId) => {
  return axiosWithSnackbar.delete(`/coach/appointment-types/${appointmentTypeId}`);
};

export const updateAppointmentType = ({
  id: appointmentTypeId,
  name,
  sessionLength,
  sessionRate,
  sortOrder = 1,
  description = null,
  bookingMessage = null,
  imageLocation = null,
  allowableModes = [],
}) => {
  return axiosWithSnackbar.patch(`/coach/appointment-types/${appointmentTypeId}`, {
    allowable_modes: JSON.stringify(allowableModes),
    name,
    image_location: imageLocation,
    description,
    booking_message: bookingMessage,
    session_length: sessionLength,
    session_rate: sessionRate,
    sort_order: sortOrder,
  });
};
