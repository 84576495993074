import React, { memo } from "react";
// import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalendarAlt,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import {
  faBoxesStacked,
  faMessage,
  faUserAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import { faCoin } from "@fortawesome/pro-light-svg-icons";

export const FOOTER_TEXTS = {
  "Company Admin": [
    {
      size: "small",
      align: "vertical",
      text: "Company Departments",
      icon: faBoxesStacked,
      url: "/company/departments",
      description:
        "Create your company departments for better filtering of your employees",
      type: "icon",
      bgColor: "bg-[#DC9884]",
    },
    {
      size: "small",
      align: "vertical",
      text: "Account Users",
      icon: faUserAlt,
      url: "/company/employees",
      description: "Manage your companies users here with easy access controls",
      type: "icon",
      bgColor: "bg-[#88C2B6]",
    },
    {
      size: "small",
      align: "vertical",
      text: "Invite Users",
      icon: faMessage,
      url: "/company/invite",
      description:
        "Invite your employees to the Coachie platform instantly today! ",
      type: "icon",
      bgColor: "bg-[#E0C645]",
    },
    {
      size: "medium",
      align: "horizontal",
      text: "Reports",
      icon: faSearch,
      url: "/company/reports",
      description:
        "See who’s been using the platform the most and benefiting from Coachie",
      type: "icon",
      bgColor: "bg-[#EDEDEC]",
    },
    {
      size: "medium",
      align: "horizontal",
      text: "Token Schedule",
      icon: faSearch,
      url: "/company/token/schedule",
      description:
        "Create schedules to issue tokens weekly, monthly or annually",
      type: "icon",
      bgColor: "bg-[#EDEDEC]",
    },
    {
      size: "medium",
      align: "horizontal",
      text: "Resources",
      icon: faSearch,
      url: "/company/resources",
      description:
        "Upload useful tools for your employees on how to get the most from the platform",
      type: "icon",
      bgColor: "bg-[#EDEDEC]",
    },
  ],
  Employee: [
    {
      size: "small",
      align: "horizontal",
      text: "Coach Search",
      icon: faSearch,
      url: "/employee/search",
      description: "Find your perfect coach",
      type: "icon",
      bgColor: "bg-[#EDEDEC]",
    },
    // {
    //   text: "Sessions",
    //   icon: faCalendarAlt,
    //   url: "/employee/sessions",
    //   description: "Buy more tokens",
    // },
    {
      size: "small",
      align: "horizontal",
      text: "Token Balance",
      isValue: true,
      icon: faCoin,
      url: "/employee/token",
      description: "Buy more tokens",
      type: "number",
      bgColor: "bg-[#DC9884]",
    },
    {
      size: "small",
      align: "horizontal",
      text: "Tools",
      icon: faWrench,
      url: "/employee/tools",
      description: "Useful tools for employees",
      type: "icon",
      bgColor: "bg-[#EDEDEC]",
    },
  ],

  // "Company Admin": [
  //   {
  //     text: "Reports",
  //     icon: faBarChart,
  //     url: "/company/reports",
  //   },
  //   {
  //     text: "Token Schedule",
  //     icon: faCalendarAlt,
  //     url: "/company/token/schedule",
  //   },
  //   {
  //     text: "Resources",
  //     icon: faWrench,
  //     url: "/company/resources",
  //   },
  // ],

  "Company Manager": [
    {
      text: "Reports",
      icon: faCalendarAlt,
    },
  ],

  "Department Manager": [
    {
      text: "-",
      icon: faSearch,
    },
    {
      text: "=",
      icon: faCalendarAlt,
    },
    {
      text: "=",
      icon: faWrench,
    },
  ],

  "Department Admin": [
    {
      text: "-",
      icon: faSearch,
    },
    {
      text: "-",
      icon: faCalendarAlt,
    },
    {
      text: "-",
      icon: faWrench,
    },
  ],

  coaches: [
    {
      text: "Sessions",
      icon: faCalendarAlt,
      url: "/coaches/sessions",
    },
  ],
};

const FooterComponent = (props) => {
  const userType = useSelector(
    (state) => state.userReducer.userDetails?.todo?.data?.role
  );

  if (!userType) {
    return <></>;
  }

  return (
    <div className="footerComponent sm:hidden h-[96px] bg-white relative z-10 w-full px-[0px] flex justify-evenly items-center">
      {props.customFooter
        ? props.customFooter
        : FOOTER_TEXTS[userType]?.map((todo, key) => (
            <Link
              key={key}
              to={todo.url ? todo.url : "/"}
              type="button"
              className="h-full text-darkColor flex flex-col justify-center items-center px-4 w-full"
            >
              {todo?.icon ? (
                <FontAwesomeIcon icon={todo?.icon} className="text-[17px]" />
              ) : null}

              <span className="text-[12px] mt-2 font-commissionerSemiBold leading-4 text-center">
                {todo.text}
              </span>
            </Link>
          ))}
    </div>
  );
};

FooterComponent.propTypes = {};

export default memo(FooterComponent);
