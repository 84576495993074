import axios from "axios";
import ButtonActions from "../../UI/ButtonActions";
import SpinnerComponent from "../../UI/SpinnerComponent";
import StyledButton from "../../UI/StyledButton";
import FormikForm from "../../forms/FormikForm/FormikForm";
import ModalComponent from "../../modal/ModalComponent";
import contactSupportFormFields from "./contactSupportFormFields";
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";

const { useState } = require("react");

const ContactSupport = () => {
  const [open, setOpen] = useState();
  const [isloading, setLoading] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const onSubmit = async (values) => {
    setLoading(true);
    axiosWithSnackbar
      .post("/problems", {
        name: values.name,
        email: values.email,
        problem: values.info,
        company: values.company,
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <>
      <div className="w-full mt-4">
        <StyledButton className="w-full" onClick={() => setOpen(true)}>
          Contact Support
        </StyledButton>
      </div>
      <ModalComponent isOpen={open} onCancel={onClose}>
        {(onClose) => (
          <div className="generalContainer text-center p-4 mx-auto flex-col">
            <h3>Contact Support</h3>
            <FormikForm
              handleSubmit={onSubmit}
              fields={contactSupportFormFields}
            >
              <ButtonActions
                buttons={[
                  <StyledButton type="submit">
                    {isloading ? <SpinnerComponent /> : "Submit"}
                  </StyledButton>,
                  <StyledButton buttonType="danger" onClick={onClose}>
                    Cancel
                  </StyledButton>,
                ]}
              />
            </FormikForm>
          </div>
        )}
      </ModalComponent>
    </>
  );
};
export default ContactSupport;
