export default function employeeAssessmentReducer(state = {}, action) {
  switch (action.type) {
    case "QUESTIONS": {
      return {
        ...state,
        questions: action.data,
      };
    }

    case "ASSESSMENT_VALUES": {
      return {
        ...state,
        assessmentValues: action.data,
      };
    }
    default:
      return state;
  }
}
