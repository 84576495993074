import React, { useEffect } from "react";
import "react-spring-bottom-sheet/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import "../app/style/main.scss";
import { useSelector } from "react-redux";
// import MainRoutes from "./app/MainRoutes";
// import OAuth2Provider from "./app/views/OAuth2Provider";
// import reportWebVitals from './test/reportWebVitals';

import * as Sentry from "@sentry/react";
import jwtServiceConfig from "./auth/services/jwtService/jwtServiceConfig";
import BrowserRouter from "./core/BrowserRouter";
import { AuthProvider } from "./auth/AuthContext";
import FuseAuthorization from "./core/FuseAuthorization";
import settingsConfig from "./auth/configs/settingsConfig";
import AppPlugins from "./AppPlugins";
import withAppProviders from "./withAppProviders";
import FuseMessage from "./core/FuseMessage";
import Layout from "./core/Layout/Layout";
import initializeSentry from "./sentryConfig";
import AuthorizationWrapper from "./core/FuseAuthorization/AuthorizationWrapper";
import DomainProvider from "./context/DomainProvider";
import LayoutProvider from "./context/LayoutProvider";
import { DomainCheck } from "./context/DomainAuthProvider";
// import FuseAuthorization from "./app/routesHook/FuseAuthorization";
initializeSentry();

function App() {
  const role = useSelector(
    (state) => state.userReducer?.userDetails?.todo?.data?.role
  );
  // const msalInstance = new PublicClientApplication(msalConfig);
  const handleCredentialResponse = async (response) => {
    jwtServiceConfig.handleGoogleRedirect(response.credential);
  };
  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });
  }, []);

  return (
    // <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter>
        <AuthProvider>
          <DomainCheck>
            <FuseMessage />
            <AuthorizationWrapper userRole={role}>
              <DomainProvider>
                <LayoutProvider>
                  <Layout />
                </LayoutProvider>
              </DomainProvider>
            </AuthorizationWrapper>
            {/* <FuseAuthorization
          userRole={role}
          loginRedirectUrl={settingsConfig.loginRedirectUrl}
        >
          <Layout/>
        </FuseAuthorization> */}
          </DomainCheck>
        </AuthProvider>

        <AppPlugins />
      </BrowserRouter>
    </Sentry.ErrorBoundary>

    // </React.StrictMode>
  );
}

export default withAppProviders(App)();
