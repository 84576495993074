import SignInConfig from "./SignInConfig";
import AccountPendingConfig from "./AccountPendingConfig";
import EmployeeConfig from "./EmployeeConfig";
import CoachConfig from "./CoachConfig";
import CompanyAdminConfig from "./CompanyAdminConfig";
import FuseUtils from "src/app/core/utils/FuseUtils";
import FuseLoading from "src/app/core/FuseLoading";
import settingsConfig from "./settingsConfig";
import AppointmentMeeting from "src/app/views/AppointmentMeeting";

const routeConfigs = [
  SignInConfig,
  AccountPendingConfig,
  EmployeeConfig,
  CoachConfig,
  CompanyAdminConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  // {
  //   path: '/',
  //   element: null,
  //   // rethinkComment:
  //   auth: settingsConfig.defaultAuth,
  // },
  // {
  //   path: 'api/v1/login/google',
  //   element: <SsoLoading />,
  // },
  // {
  //   path: 'api/v1/login/graph',
  //   element: <SsoLoading />,
  // },
  {
    path: "appointment/:appointmentToken",
    element: <AppointmentMeeting />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  // {
  //   path: '*',
  //   // element: <Navigate to="/" />,
  //   element: <h1>Not found</h1>,
  // },
];

export default routes;
