import React, { useEffect, useState } from "react";
import userAvatar from "src/assets/user_avatar.png";

const ImageWithFallback = ({ fallbackSrc = userAvatar, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(userAvatar);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleError = () => {
    setImgSrc(fallbackSrc);
  };

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={imgSrc || userAvatar} onError={handleError} {...props} />;
};

export default ImageWithFallback;
