import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { matchRoutes } from "react-router-dom";
import AppContext from "src/app/context/AppContext";
import FuseUtils from "../utils/FuseUtils";
import { userRedirects } from "src/app/auth/authRoles";

let loginRedirectUrl = null;

const getMatchedRoute = (routes, pathname) => {
  const matchedRoutes = matchRoutes(routes, pathname);
  const matched = matchedRoutes ? matchedRoutes[0] : false;
  return matched;
};
const isRouteExists = (matched) => matched?.route;
const saveThePath = (pathname) => {
  loginRedirectUrl = pathname;
  // Implement saving the path logic
};
const userHasPermissionOfThisRoute = (auth, userRole) => {
  // Implement permission check
  return FuseUtils.hasPermission(auth, userRole);
};
const AuthorizationWrapper = ({ children, userRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { routes } = useContext(AppContext);
  useEffect(() => {
    const isUserLoggedIn = userRole;
    const pathname = location.pathname;
    const matchedRoute = getMatchedRoute(routes, pathname);
    console.log(matchedRoute, "matchedRoute");
    console.log(routes, "routes");
    console.log(pathname, "pathname");
    if (isRouteExists(matchedRoute)) {
      if (isUserLoggedIn) {
        if (!userHasPermissionOfThisRoute(matchedRoute.route.auth, userRole)) {
          navigate(userRedirects[userRole]);
        } else {
          if (loginRedirectUrl) {
            loginRedirectUrl === "/"
              ? navigate(userRedirects[userRole])
              : navigate(loginRedirectUrl);
          } else if (pathname === "/login" || pathname === "/") {
            navigate(userRedirects[userRole]);
          }
        }
      }
    } else {
      console.log(0);
      navigate(isUserLoggedIn ? userRedirects[userRole] : "/login");
    }
  }, [userRole, location.pathname]);

  return children;
};

export default AuthorizationWrapper;
