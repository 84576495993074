export const ERROR_MESSAGES = {
  required: "Field is Required",
  invalidEmailAddress: "Invalid Email Address",
  employeeEditProfile: {
    address: "",
    contactNumber: "Contact number is Invalid",
    firstName: "",
    lastName: "",
    jobTitle: "",
  },
};
