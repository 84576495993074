import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userLoginActions,
  userGoogleSignOnActions,
  userFacebookSignOnActions,
  userMicrosoftSignOnActions,
  userLogoutActions,
  coachLoginActions,
  coachSignUpActions,
} from "src/app/store/actions/userActions";
import { COACHES_DOMAIN } from "src/const";
import useDomain from "../generalHooks/useDomain";

const useRequestHook = () => {
  const domainName = useDomain();

  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isOtp, setIsOtp] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [otp, setOtp] = useState("");

  const userDetails = useSelector((state) => state.userReducer.userDetails);
  const coachSignedUpUser = useSelector(
    (state) => state.userReducer.coachSignedUpUser
  );
  const isCoach = domainName === COACHES_DOMAIN;
  useEffect(() => {
    if (coachSignedUpUser) {
      if (!coachSignedUpUser.pending) {
        if (!coachSignedUpUser.failed) {
          setRedirectToLogin(true);
        }
      }
    }
  }, [coachSignedUpUser]);

  useEffect(() => {
    if (userDetails) {
      setUser(userDetails);
      if (!userDetails.pending) {
        if (!userDetails.failed) {
          if (userDetails?.todo.data?.otp) {
            setIsOtp(true);
          }
        }
      }
    }
  }, [userDetails]);

  const onLogout = useCallback(async () => {
    await userLogoutActions(dispatch);
    window.location.href = "/login";
  }, [dispatch]);

  const submit = useCallback(
    async (values, { setSubmitting }) => {
      if (isCoach) {
        await coachLoginActions(dispatch, values, false);
        setSubmitting(false);
        return;
      }
      await userLoginActions(dispatch, values, false);
      setSubmitting(false);
    },

    [dispatch]
  );

  const coachSignupSubmit = useCallback(
    async (values, { setSubmitting }) => {
      await coachSignUpActions(dispatch, values);
      setSubmitting(false);
    },
    [dispatch]
  );

  const googleSignOn = useCallback(async () => {
    await userGoogleSignOnActions();
  }, []);

  const facebookSignOn = useCallback(async () => {
    await userFacebookSignOnActions();
  }, []);

  const microsoftSignOn = useCallback(async () => {
    await userMicrosoftSignOnActions();
  }, []);

  const handleOtp = useCallback(
    (e) => {
      setOtp(e);
    },
    [isOtp]
  );

  const otpCancel = useCallback(() => {
    setIsOtp(false);
  }, []);

  const loginWithCode = useCallback(async () => {
    const meta = userDetails?.todo?.data?.meta;
    if (otp.length === 4 && meta) {
      if (isCoach) {
        await coachLoginActions(dispatch, { ...meta, otpCode: otp }, isOtp);
      } else {
        await userLoginActions(dispatch, { ...meta, otpCode: otp }, isOtp);
      }

      handleOtp("");
    } else {
      console.log("should not triggered");
    }
  }, [userDetails?.todo?.data?.meta, otp, isCoach, handleOtp, dispatch, isOtp]);

  return {
    submit,
    facebookSignOn,
    googleSignOn,
    user,
    microsoftSignOn,
    isOtp,
    otp,
    otpCancel,
    handleOtp,
    loginWithCode,
    onLogout,
    isCoach,
    coachSignupSubmit,
    redirectToLogin,
  };
};

export default useRequestHook;
