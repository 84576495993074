const ButtonActions = ({ buttons, position = "end", className, ...props }) => {
  return (
    <div
      className={`my-4 w-full flex flex-wrap justify-${position} ${
        className || ""
      } gap-2`}
      {...props}
    >
      {buttons.map((button) => button)}
    </div>
  );
};
export default ButtonActions;
