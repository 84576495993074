import { lazy } from "react";
import authRoles from "../authRoles";
import Coach from "src/app/views/coach/Coach";
const CoachDashboardView = lazy(() =>
  import("src/app/views/coach/CoachDashboardView")
);
const CoachEditProfile = lazy(() =>
  import("src/app/views/coach/CoachEditProfile")
);
const CoachEditProfileStep = lazy(() =>
  import("src/app/views/coach/CoachEditProfile/CoachEditProfileStep")
);
const CoachEditProfileView = lazy(() =>
  import("src/app/views/coach/CoachEditProfileView")
);
const CoachOnboarding = lazy(() =>
  import("src/app/views/coach/CoachOnboarding")
);
const CoachOnboardingStep = lazy(() =>
  import("src/app/views/coach/CoachOnboarding/CoachOnboardingStep")
);
const CoachPayoutView = lazy(() =>
  import("src/app/views/coach/CoachPayoutView")
);
const CoachProfileView = lazy(() =>
  import("src/app/views/coach/CoachProfileView")
);
const CoachTokenSummaryView = lazy(() =>
  import("src/app/views/coach/CoachTokenSummaryView")
);
const CoachTransactionView = lazy(() =>
  import("src/app/views/coach/CoachTransactionView")
);
const CoachUpComingSessionView = lazy(() =>
  import("src/app/views/coach/CoachUpComingSessionView")
);
const CoachesSessionsDetailView = lazy(() =>
  import("src/app/views/coach/CoachesSessionsDetailView")
);
const CoachesSessionsView = lazy(() =>
  import("src/app/views/coach/CoachesSessionsView")
);
const CoachesVerificationView = lazy(() =>
  import("src/app/views/coach/CoachesVerificationView")
);
const CoachAvailabilitySessionView = lazy(() =>
  import("src/app/views/coach/CoachAvailabilitySessionView")
);

const CoachConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.coaches,
  routes: [
    {
      path: "coaches",
      element: <Coach />,
      children: [
        {
          path: "",
          element: <CoachDashboardView />,
        },
        {
          path: "upcoming",
          children: [
            {
              path: "",
              element: <CoachUpComingSessionView />,
            },
            {
              path: ":date",
              element: <CoachUpComingSessionView />,
            },
          ],
        },
        {
          path: "availability",
          element: <CoachAvailabilitySessionView />,
        },
        {
          path: "transaction",
          element: <CoachTransactionView />,
        },
        {
          path: "payout",
          element: <CoachPayoutView />,
        },
        {
          path: "tokensummary",
          element: <CoachTokenSummaryView />,
        },
        {
          path: "profile",
          children: [
            {
              path: "",
              element: <CoachProfileView />,
            },
            {
              path: "edit",
              element: <CoachEditProfile />,
              children: [
                {
                  path: ":step",
                  element: <CoachEditProfileStep />,
                },
              ],
            },
            {
              path: "resources/edit",
              element: <CoachEditProfileView />,
            },
          ],
        },
        {
          path: "sessions",
          children: [
            {
              path: "",
              element: <CoachesSessionsView />,
            },
            {
              path: "details",
              element: <CoachesSessionsDetailView />,
            },
          ],
        },
        {
          path: "verify",
          element: <CoachesVerificationView />,
        },
      ],
    },
    {
      path: "coaches/create",
      auth: null,
      element: <CoachOnboarding />,
      children: [
        {
          path: ":step",
          element: <CoachOnboardingStep />,
        },
      ],
    },
  ],
};

export default CoachConfig;
