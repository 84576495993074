/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import { matchRoutes } from 'react-router-dom';
import AppContext from "src/app/context/AppContext";
import history from "../Components/history";
import withRouter from "../withRouter";
import FuseUtils from "../utils/FuseUtils";
let loginRedirectUrl = null;

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
    this.defaultLoginRedirectUrl = props.loginRedirectUrl || '/';
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.accessGranted !== this.state.accessGranted ||
      nextProps.userRole !== this.props.userRole
    )
  }
  componentDidMount()
  {
    this.redirectRoute();
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
    else if(this.state.accessGranted && this.props.userRole)
    {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { pathname } = location;
    const matchedRoutes = matchRoutes(state.routes, pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;
    return {
      accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true,
    };
  }

  redirectRoute() {

    const { location, userRole } = this.props;
    const { pathname } = location;
    if (!userRole || userRole.length === 0) {
      loginRedirectUrl = pathname;
      setTimeout(() => history.push('/login'), 0);
    } else {
      // const redirectUrl = loginRedirectUrl || this.defaultLoginRedirectUrl;
      // setTimeout(() => history.push(redirectUrl), 0);
      // loginRedirectUrl = this.defaultLoginRedirectUrl;
    }
  }

  render() {
    return this.state.accessGranted ? this.props.children : null;
  }
}

FuseAuthorization.contextType = AppContext;

export default withRouter(FuseAuthorization)
