// DomainProvider.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SplashView from "../views/SplashView";
import { checkSubdomainApi } from "../store/apis/routesApis";
export const DomainCheck = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const subdomain = window.location.hostname.split(".")[0];
    if (subdomain !== "coaches") {
      checkSubdomainApi(subdomain)
        .then((response) => {
          if (!response.status === 200 && !response.status === 201) {
            navigate("/prohibited");
          }
        })
        .catch((err) => {
          if (err.response.data.message === "Not found") {
            navigate("/prohibited");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <SplashView />;
  }
  return children;
};
