import { lazy } from 'react';
import authRoles from "../authRoles";
import Initialize from "src/app/views/Initialize";


const CompanyDashboardView = lazy(() => import("src/app/views/company/CompanyDashboardView"));
const CompanyDepartmentDataView = lazy(() => import("src/app/views/company/CompanyDepartmentDataView"));
const CompanyDepartmentDetailsView = lazy(() => import("src/app/views/company/CompanyDepartmentDetailsView"));
const CompanyDepartmentsView = lazy(() => import("src/app/views/company/CompanyDepartmentsView"));
const CompanyEditProfileView = lazy(() => import("src/app/views/company/CompanyEditProfileView"));
const CompanyEmployeeInviteView = lazy(() => import("src/app/views/company/CompanyEmployeeInviteView"));
const CompanyEmployeeList = lazy(() => import("src/app/views/company/CompanyEmployeeList"));
const CompanyProfileView = lazy(() => import("src/app/views/company/CompanyProfileView"));
const CompanyReportsView = lazy(() => import("src/app/views/company/CompanyReportsView"));
const CompanyTokenScheduleByIdEditView = lazy(() => import("src/app/views/company/CompanyTokenScheduleByIdEditView"));
const CompanyTokenScheduleByIdView = lazy(() => import("src/app/views/company/CompanyTokenScheduleByIdView"));
const CompanyTokenScheduleView = lazy(() => import("src/app/views/company/CompanyTokenScheduleView"));
const CompanyToolsView = lazy(() => import("src/app/views/company/CompanyToolsView"));
const CompanyTransactionView = lazy(() => import("src/app/views/company/CompanyTransactionView"));

const CompanyAdminConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.companyAdmin,
  routes: [
    {
      path: 'company',
      element: <Initialize />,
      children: [
        {
          path: '',
          element: <CompanyDashboardView />,
        },
        {
          path: 'profile/edit',
          element: <CompanyEditProfileView />,
        },
        {
          path: 'edit',
          element: <CompanyProfileView />,
        },
        {
          path: 'departments',
          children: [
            {
              path: '',
              element: <CompanyDepartmentsView />,
            },
            {
              path: ':id',
              element: <CompanyDepartmentDetailsView />,
            },
            {
              path: 'create',
              element: <CompanyDepartmentDataView />,
            },
            {
              path: 'update/:id',
              element: <CompanyDepartmentDataView />,
            },
          ],
        },
        {
          path: 'invite',
          element: <CompanyEmployeeInviteView/>,
        },
        {
          path: 'employees',
          element: <CompanyEmployeeList />,
        },
        {
          path: 'resources',
          element: <CompanyToolsView />,
        },
        {
          path: 'reports',
          element: <CompanyReportsView />,
        },
        {
          path: 'token/schedule',
          children: [
            {
              path: '',
              element: <CompanyTokenScheduleView />,
            },
            {
              path: ':id',
              element: <CompanyTokenScheduleByIdView />,
            },
            {
              path: 'view',
              children: [
                {
                  path: '',
                  element: <CompanyTokenScheduleByIdEditView />,
                },
                {
                  path: ':id',
                  element: <CompanyTokenScheduleByIdEditView />,
                },
              ],
            },
          ],
        },
        {
          path: 'transaction',
          element: <CompanyTransactionView />,
        },
      ],
    },
  ],
};

export default CompanyAdminConfig