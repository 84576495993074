import { memo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

const ModalComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      const setOpenTimer = setTimeout(() => {
        setIsOpen(props.isOpen);
        clearTimeout(setOpenTimer);
      }, 500);
    }
  }, [props.isOpen]);

  const onClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      const setOpenTimer = setTimeout(() => {
        props.onCancel();
        clearTimeout(setOpenTimer);
      }, 500);
    }
  }, [props.isOpen, isOpen]);

  if (!props.isOpen) return <></>;

  return (
    <div className={`modelComponent justify-center ${props.className || ""}`}>
      <div
        className={`modelComponentOverlay z-[99] flex items-center justify-center fixed bg-[#00000070] top-0 left-0 bottom-0 right-0 ${
          isOpen ? "modelComponentOverlayAnimate" : ""
        }`}
      >
        <div className="modelComponentBody max-h-full bg-white max-w-[420px] w-full mx-4 min-h-[100px] rounded-lg">
          {props.children(onClose)}
        </div>
      </div>
    </div>
  );
};

ModalComponent.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.any]),
};

export default memo(ModalComponent);
