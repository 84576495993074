// sentryConfig.js
import * as Sentry from '@sentry/react';
import React from "react";

import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  } from "react-router-dom";
  
const initializeSentry = () => {
Sentry.init({
  dsn: "https://768efe5806b68aa6716b10f073a1d65b@o4506184553988096.ingest.sentry.io/4506184909848576",
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracePropagationTargets: ["localhost:3000", /^https:\/\/(.*\.)?coachie\.io/],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0,
});
};

export default initializeSentry;
