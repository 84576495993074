// App.js or similar
import React from "react";
import DomainContext from "./DomainContext";
import { getDomain } from "src/helpers/utils";

function DomainProvider({ children }) {
  const domainName = getDomain();
  return (
    <DomainContext.Provider value={domainName}>
      {children}
    </DomainContext.Provider>
  );
}

export default DomainProvider;
