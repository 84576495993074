import React from "react";
import userAvatar from "src/assets/user_avatar.png";

const Avatar = ({ src, alt, size = "w-24 h-24" }) => {
  const handleError = (e) => {
    e.target.src = userAvatar;
  };
  return (
    <div
      className={`relative rounded-full overflow-hidden flex items-center justify-center bg-gray-200 text-white ${size}`}
    >
      <img
        src={src || userAvatar}
        alt={alt}
        onError={handleError}
        className="absolute inset-0 w-full h-full object-cover"
      />
      {/* {!src && initials && (
        <span className="text-xl font-semibold">{initials}</span>
      )} */}
    </div>
  );
};

export default Avatar;
