// import { utils } from "../../helpers/utils";
// import _ from "lodash";

export const loginMapKeys = (values) => {
  const obj = {
    username: values.email,
    password: values.password,
    otp_code: values.otpCode,
  };
  return obj;
};

export const signupMapKeys = (values) => {
  const obj = {
    name: values.name || undefined,
    email: values.email || undefined,
    password: values.password || undefined,
    timezone: values.timezone || undefined,
    gender: values.gender || undefined,
    pronouns: values.pronouns || undefined,
  };
  return obj;
};
