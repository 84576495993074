import axios from 'axios';
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";
import { getBaseSearchParams } from "src/helpers/utils";

export const fetchSpecialisms = (searchParams = getBaseSearchParams()) => {
  return axios.get(`/coach/specialisms?${searchParams.toString()}`);
};

export const fetchAssignedSpecialisms = (searchParams = getBaseSearchParams()) => {
  return axios.get(`/coach/specialisms/assigned?${searchParams.toString()}`);
};

export const fetchSpecialismById = (categoryId) => {
  return axios.get(`/coach/specialism/${categoryId}`);
};

export const assignSpecialism = ({ id }) => {
  return axiosWithSnackbar.post(`/coach/specialism/assign`, {
    category_specialism_id: id,
  });
};

export const removeSpecialism = ({ id }) => {
  return axios.post(`/coach/specialism/remove`, {
    category_specialism_id: id,
  });
};
