import React, { memo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars-2";

import logoLight from "src/assets/logo_light.png";
import useSideNavHook from "./hooks/useSideNavHook";
import ContactSupport from "../views/contactSupport/ContactSupport";
import { useSelector } from "react-redux";
import Avatar from "../UI/Avatar";
import { useNavigate } from "react-router-dom";
import userAvatar from "src/assets/user_avatar.png";

const SideNavComponent = (props) => {
  const navigate = useNavigate();
  const user = useSelector(
    (state) => state.userReducer?.currentUser?.todo?.data
  );
  const { closeUi, initial, GetSideNavComponent } = useSideNavHook(props);

  if (!props.open) return <></>;
  return (
    <div className="sideNavComponent sm:w-[260px]">
      <div
        className={`overlay ${
          initial ? "overlay-opacity-1" : ""
        } z-20 fixed top-0 left-[0px] bottom-0 right-0 w-full sm:hidden`}
      />
      <div
        className="z-20 fixed sm:relative top-0 -left-[0px] bottom-0 w-full sm:h-full"
        onClick={closeUi}
      >
        {/* <div
          className="absolute right-[20px] top-[53px] sm:hidden"
          onClick={closeUi}
        >
          <FontAwesomeIcon icon={faClose} className="text-[21px] text-white" />
        </div> */}
        <div
          onClick={(e) => e.stopPropagation()}
          className={`absolute sm:relative sideNavSlider ${
            initial ? "sideNavSlider-active" : ""
          } top-0 bottom-0 sm:h-full sm:w-full min-w-[260px] bg-bgSideNavColor px-[40px] sm:px-[18px] py-[46px] sm:py-[26px] flex flex-col w-full`}
        >
          <div className="whiteLogo mb-12 relative">
            {/* <img src={logoLight} alt="coachie" /> */}
            <div className="flex justify-between">
              <div
                className="w-32 cursor-pointer"
                onClick={() => navigate("/")}
              >
                <img
                  src={logoLight}
                  alt="Logo"
                  className="object-contain w-full h-full"
                />
              </div>
              <div
                onClick={props.onClose}
                className="flex items-end justify-center cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-[20px] text-white"
                />
              </div>
            </div>

            <div className="user relative flex flex-col items-center justify-center p-16 pb-4">
              <div className="flex items-center justify-center mb-4">
                <Avatar
                  src={user?.logoImage || user?.tempUrl || userAvatar}
                  alt="user"
                  initials={user?.name?.charAt(0)}
                  size="w-32 h-32"
                />
                {/* <div className="avatar flex items-center justify-center text-32 font-bold w-24 h-24 rounded-full bg-gray-100 text-gray-600 overflow-hidden">
                  <img src={user?.logoImage} alt="user" className="w-full h-full" />
                </div> */}
              </div>
              <div className="username text-14 whitespace-nowrap text-white break-words font-semibold text-sm">
                {user?.name}
              </div>
              <div className="email text-13 whitespace-nowrap text-gray-600 text-gray break-words font-semibold text-sm">
                {user?.email}
              </div>
            </div>
          </div>

          <div className="flex-1">
            <Scrollbars style={{ height: "100%" }}>
              <GetSideNavComponent />
            </Scrollbars>
          </div>
          <ContactSupport />
        </div>
      </div>
    </div>
  );
};

SideNavComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(SideNavComponent);
