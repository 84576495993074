// helpers
import { utils } from "src/helpers/utils";
import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { uploadFile } from "react-s3";

// api's
import {
  loginApi,
  resetPasswordApi,
  googleSignOnApi,
  facebookSignOnApi,
  microsoftSignOnApi,
  providerSignOnCallbackApi,
  getCurrentUserDetailsApi,
  updateCurrentUserProfileApi,
  getCurrentUserDepartmentsApi,
  updateCurrentCompanyAdminApi,
  coachLoginApi,
  coachSignUpApi,
  getCurrentCoachDetailsApi,
  updateCurrentCoachProfileApi,
  getCurrentUserAccountApi,
  inviteUserApi,
} from "../apis/userApis";
// mapper
import { loginMapKeys, signupMapKeys } from "../models/userModel";
import responseContainer from "./utils/responseController";
import { url } from "src/helpers/url";
import JwtService from "src/app/auth/services/jwtService";
import {
  USER_ROLE,
  USER_TOKEN,
} from "src/app/auth/services/jwtService/jwtServiceConfig";

const { successor, failed } = responseContainer;
window.Buffer = window.Buffer || require("buffer").Buffer;

export const userIsLoggedIn = async (dispatch) => {
  const getUserFromCache = utils.getCache(USER_TOKEN);
  if (!isEmpty(getUserFromCache)) {
    return dispatch({ type: "LOGIN", data: successor(getUserFromCache) });
  }
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };

  return dispatch({ type: "LOGIN", data: META });
};

export const userLogoutActions = async (dispatch) => {
  utils.removeCache(USER_TOKEN);
  utils.removeCache(USER_ROLE);
  return dispatch({ type: "LOGOUT", data: null });
};

export const userLoginActions = async (
  dispatch,
  { password, email, remember = false, otpCode = undefined },
  isOtp = false
) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };

  dispatch({ type: "LOGIN", data: META });
  const todo = loginMapKeys({ password, email, otpCode });
  return loginApi(todo)
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200) {
        JwtService.setTokenHeader(getJsonResponse.access_token);
        //  Temporary
        if (!getJsonResponse.role?.length) {
          console.log("ROLE NOT FOUND IN LOGIN API");
          getJsonResponse.role = "Employee";
        } else {
          getJsonResponse.role =
            getJsonResponse.role[getJsonResponse.role.length - 1];
        }
        const successData = successor({ ...getJsonResponse });
        dispatch({
          type: "LOGIN",
          data: successData,
        });
        utils.setCache({ ...getJsonResponse }, USER_TOKEN);
        JwtService.setUserRole(getJsonResponse.role);
        return successData;
      }
      if (
        response.status === 401 &&
        getJsonResponse.error === "otpfail" &&
        isOtp === false
      ) {
        const successData = successor({
          otp: true,
          meta: { password, email, remember },
        });
        dispatch({
          type: "LOGIN",
          data: successData,
        });
        return successData;
      }
      if (isOtp === true) {
        getJsonResponse = {
          ...getJsonResponse,
          data: {
            otp: true,
            meta: { password, email, remember },
          },
        };
      }
      const failedData = failed(getJsonResponse);
      dispatch({ type: "LOGIN", data: failedData });
      return failedData;
    })
    .catch((e) => {
      const failedData = failed(e);
      dispatch({ type: "LOGIN", data: failed(e) });
      return failedData;
    });
};

export const coachLoginActions = async (
  dispatch,
  { password, email, remember = false, otpCode = undefined },
  isOtp = false
) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };

  dispatch({ type: "LOGIN", data: META });
  const todo = loginMapKeys({ password, email, otpCode });
  return coachLoginApi(todo)
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200) {
        JwtService.setTokenHeader(getJsonResponse.access_token);
        //Temporary
        if (!getJsonResponse.role?.length) {
          getJsonResponse.role = "coaches";
        }
        const successData = successor({ ...getJsonResponse });
        dispatch({
          type: "LOGIN",
          data: successData,
        });
        utils.setCache({ ...getJsonResponse }, USER_TOKEN);
        JwtService.setUserRole(getJsonResponse.role);
        return successData;
      }
      if (
        response.status === 401 &&
        getJsonResponse.error === "otpfail" &&
        isOtp === false
      ) {
        return dispatch({
          type: "LOGIN",
          data: successor({
            otp: true,
            meta: { password, email, remember },
          }),
        });
      }
      if (isOtp === true) {
        getJsonResponse = {
          ...getJsonResponse,
          data: {
            otp: true,
            meta: { password, email, remember },
          },
        };
      }
      return dispatch({ type: "LOGIN", data: failed(getJsonResponse) });
    })
    .catch((e) => {
      return dispatch({ type: "LOGIN", data: failed(e) });
    });
};

export const coachSignUpActions = async (dispatch, values) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };

  dispatch({ type: "COACH_SIGNUP", data: META });
  const todo = signupMapKeys(values);
  return coachSignUpApi(todo)
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200 || response.status === 201) {
        return dispatch({
          type: "COACH_SIGNUP",
          data: successor({ ...getJsonResponse }),
        });
      }
      return dispatch({ type: "COACH_SIGNUP", data: failed(getJsonResponse) });
    })
    .catch((e) => {
      return dispatch({ type: "COACH_SIGNUP", data: failed(e) });
    });
};

export const inviteUserActions = async (userDetails, todo) => {
  return inviteUserApi(utils.toSnakeCase(todo))
    .then(async (response) => {
      const getJsonResponse = response.data;
      if (response.status === 201) {
        return successor(getJsonResponse);
      }
      return failed(getJsonResponse);
    })
    .catch((e) => {
      return failed(e);
    });
};

export const userResetActions = async ({ email }) => {
  return resetPasswordApi({ email })
    .then(async (response) => {
      const getJsonResponse = response.data;
      if (response.status === 200) {
        return successor(getJsonResponse);
      }
      return failed(getJsonResponse);
    })
    .catch((e) => {
      return failed(e);
    });
};

export const userAccessTokenActions = async (dispatch, todo) => {
  return providerSignOnCallbackApi(todo)
    .then(async (response) => {
      const getJsonResponse = response.data;
      if (response.status === 200) {
        utils.setCache(getJsonResponse, USER_TOKEN);
        return dispatch({ type: "LOGIN", data: successor(getJsonResponse) });
      }
      return dispatch({ type: "LOGIN", data: failed(getJsonResponse) });
    })
    .catch((e) => {
      return dispatch({ type: "LOGIN", data: failed(e) });
    });
};

export const userGoogleSignOnActions = async () => {
  return googleSignOnApi()
    .then(async (response) => {
      const getJsonResponse = response.data;
      if (response.status === 200) {
        window.location.href = getJsonResponse.redirect_url;
      }
      return failed(getJsonResponse);
    })
    .catch((e) => {
      return failed(e);
    });
};

export const userFacebookSignOnActions = async () => {
  return facebookSignOnApi()
    .then(async (response) => {
      const getJsonResponse = response.data;
      if (response.status === 200) {
        if (response.status === 200) {
          window.location.href = getJsonResponse.redirect_url;
        }
      }
      return failed(getJsonResponse);
    })
    .catch((e) => {
      return failed(e);
    });
};

export const userMicrosoftSignOnActions = async () => {
  return microsoftSignOnApi()
    .then(async (response) => {
      const getJsonResponse = response.data;
      if (response.status === 200) {
        if (response.status === 200) {
          window.location.href = getJsonResponse.redirect_url;
        }
      }
      return failed(getJsonResponse);
    })
    .catch((e) => {
      return failed(e);
    });
};

export const userGetMyDetailsActions = async (dispatch, userDetails) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };
  dispatch({ type: "CURRENT_USER", data: META });
  return getCurrentUserDetailsApi()
    .then(async (response) => {
      try {
        const departmentsApi = await getCurrentUserDepartmentsApi();
        const accountsApi = await getCurrentUserAccountApi();
        let departments = departmentsApi.data;
        let accounts = accountsApi.data;
        let getJsonResponse = response.data;
        if (
          response.status === 200 &&
          (departmentsApi.status === 200 || accounts.status === 200)
        ) {
          const processData = utils.toCamelCase(getJsonResponse.data);
          const accountsData = utils.toCamelCase(accounts.data);
          const departmentData = utils.arrayObjectsToCamelCase(
            departments.data
          );

          const merge = {
            ...processData,
            profileImage: processData.profileImage
              ? url(processData.profileImage)
              : null,
            departments: departmentData.map((v) => ({
              ...v,
              logoImage: v.logoImage ? url(v.logoImage) : null,
            })),
            accounts: {
              ...accountsData,
              logoImage: accountsData.logoImage
                ? url(accountsData.logoImage)
                : null,
            },
          };
          return dispatch({
            type: "CURRENT_USER",
            data: successor(merge),
          });
        }

        return dispatch({
          type: "CURRENT_USER",
          data: failed({ ...getJsonResponse, departments, accounts }),
        });
      } catch (e) {
        console.log(e, "error");
      }
    })
    .catch((e) => {
      return dispatch({ type: "CURRENT_USER", data: failed(e) });
    });
};

export const coachGetMyDetailsActions = async (dispatch, userDetails) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };
  dispatch({ type: "CURRENT_USER", data: META });

  return getCurrentCoachDetailsApi()
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200) {
        const processData = utils.toCamelCase(getJsonResponse.data);
        const merge = {
          ...processData,
          logoImage: processData.logoImage ? url(processData.logoImage) : null,
        };

        return dispatch({
          type: "CURRENT_USER",
          data: successor(merge),
        });
      }
      return dispatch({
        type: "CURRENT_USER",
        data: failed({ ...getJsonResponse }),
      });
    })
    .catch((e) => {
      return dispatch({ type: "CURRENT_USER", data: failed(e) });
    });
};

const dataURLtoFile = (dataUrl, filename) => {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const uploadProfileImage = async (dataUrl, id) => {
  const fileName = `${id}/profileImage`;
  const file = dataURLtoFile(dataUrl, v4());
  const config = utils.getAwsCred;
  try {
    const response = await uploadFile(file, { ...config, dirName: fileName });
    return { location: response.key };
  } catch (e) {
    return {
      location: "",
    };
  }
};

export const uploadCoachDocuments = async ({ coachId, type, fileData }) => {
  const fileName = `coachDocuments/${coachId}/${type}/${v4()}`;
  const file = fileData.originalFile;

  const config = utils.getAwsCred;
  try {
    // await deleteFile(`*`, {...config, dirName: fileName});
    const response = await uploadFile(file, { ...config, dirName: fileName });
    return { location: response.key };
  } catch (e) {
    return {
      location: "",
    };
  }
};

export const coachUploadCVDocument = async (file) => {
  const fileName = `coaches/cv`;

  const config = utils.getAwsCred;
  try {
    const response = await uploadFile(file, { ...config, dirName: fileName });
    return response.key;
  } catch (e) {
    return "";
  }
};
export const updateCurrentCoachProfileActions = async (
  dispatch,
  userDetails,
  params
) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };
  dispatch({ type: "UPDATE_CURRENT_USER", data: META });
  return updateCurrentCoachProfileApi(utils.toSnakeCase(params.values))
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200 || response.status === 202) {
        const processData = utils.toCamelCase(getJsonResponse.data);
        return dispatch({
          type: "UPDATE_CURRENT_USER",
          data: successor(processData),
        });
      }
      return dispatch({
        type: "UPDATE_CURRENT_USER",
        data: failed(getJsonResponse),
      });
    })
    .catch((e) => {
      return dispatch({ type: "UPDATE_CURRENT_USER", data: failed(e) });
    });
};

export const updateCurrentCompanyProfileActions = async (
  dispatch,
  userDetails,
  params
) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };
  dispatch({ type: "UPDATE_CURRENT_COMPANY", data: META });
  return updateCurrentCompanyAdminApi(
    utils.toSnakeCase(params.values),
    params.hookup
  )
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200 || response.status === 202) {
        const processData = utils.toCamelCase(getJsonResponse.data);
        return dispatch({
          type: "UPDATE_CURRENT_COMPANY",
          data: successor(processData),
        });
      }
      return dispatch({
        type: "UPDATE_CURRENT_COMPANY",
        data: failed(getJsonResponse),
      });
    })
    .catch((e) => {
      return dispatch({ type: "UPDATE_CURRENT_COMPANY", data: failed(e) });
    });
};

export const updateCurrentUserProfileActions = async (
  dispatch,
  userDetails,
  params
) => {
  const META = {
    initial: true,
    pending: true,
    failed: false,
    message: "Pending...",
    todo: {
      data: null,
      error: null,
    },
  };
  dispatch({ type: "UPDATE_CURRENT_USER", data: META });
  return updateCurrentUserProfileApi(
    utils.toSnakeCase(params.values),
    params.hookup
  )
    .then(async (response) => {
      let getJsonResponse = response.data;
      if (response.status === 200 || response.status === 202) {
        const processData = utils.toCamelCase(getJsonResponse.data);
        return dispatch({
          type: "UPDATE_CURRENT_USER",
          data: successor(processData),
        });
      }
      return dispatch({
        type: "UPDATE_CURRENT_USER",
        data: failed(getJsonResponse),
      });
    })
    .catch((e) => {
      return dispatch({ type: "UPDATE_CURRENT_USER", data: failed(e) });
    });
};
