import {
  ONBOARDING_UPDATE_BOOTSTRAP_DATA,
  ONBOARDING_UPDATE_FORM_DATA,
  ONBOARDING_CLEANUP_DATA,
} from "../actions/onboardingActions";

const initialState = {
  formData: {},
  bootstrapData: {},
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_UPDATE_BOOTSTRAP_DATA: {
      return {
        ...state,
        bootstrapData: {
          ...state.bootstrapData,
          ...action.payload,
        },
      };
    }

    case ONBOARDING_UPDATE_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    }

    case ONBOARDING_CLEANUP_DATA: {
      return {
        formData: {},
        bootstrapData: {},
      };
    }

    default: {
      return state;
    }
  }
};

export default onboardingReducer;
