import { useDispatch } from 'react-redux';
import { showMessage } from "src/app/store/actions/snackbarActions";

export default function useSnackbarMessage() {
  const dispatch = useDispatch();
  const setSnackbarMessage = (message, variant) => {
    dispatch(showMessage({ message, variant:variant || "info" }));
  };
  return setSnackbarMessage;
}
