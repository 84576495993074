// eslint-disable-next-line max-classes-per-file
import { isEqual, isObject, merge, omit, transform } from 'lodash';
import moment from 'moment';

class EventEmitter {
  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn);
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = (...args) => {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach(
      // eslint-disable-next-line func-names
      function (fn) {
        fn.apply(this, args);
      }.bind(this)
    );
  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}

class FuseUtils {
  static filterArrayByString(mainArr, searchText) {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
  }

  static appendExistingParams(path) {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Extract the query parameters from the current URL
    const currentSearchParams = currentURL.search;

    // Append the query parameters to the provided path
    const newPath = `${path}${currentSearchParams}`;

    // Return the new path
    return newPath;
  }

  static convertMinutes(input) {
    const duration = moment.duration(input, 'minutes');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    let output = '';
    if (hours > 0) {
      output += `${hours} hour${hours > 1 ? 's' : ''} `;
    }
    if (minutes > 0) {
      output += `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return output.trim();
  }

  static formatDate(from, to) {
    return `${moment(from).format('hh:mm A')}-${moment(to).format('hh:mm A')}`;
  }

  static nextDate() {
    return moment().add(1, 'days').toDate();
  }

  static searchInObj(itemObj, searchText) {
    if (!itemObj) {
      return false;
    }

    const propArray = Object.keys(itemObj);

    for (let i = 0; i < propArray.length; i += 1) {
      const prop = propArray[i];
      const value = itemObj[prop];

      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
    return false;
  }

  static stringifyAndParse(object) {
    return JSON.parse(JSON.stringify(object));
  }

  static searchInArray(arr, searchText) {
    arr.forEach((value) => {
      if (typeof value === 'string') {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === 'object') {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
      return false;
    });
    return false;
  }

  static searchInString(value, searchText) {
    return value.toLowerCase().includes(searchText);
  }

  static generateGUID() {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  static toggleInArray(item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  static handleize(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/\W+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    routes = routes.map((route) => {
      let auth = config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth,
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)];
    });
    return allRoutes;
  }

  static findById(obj, id) {
    let i;
    let childObj;
    let result;

    if (id === obj.id) {
      return obj;
    }

    for (i = 0; i < Object.keys(obj).length; i += 1) {
      childObj = obj[Object.keys(obj)[i]];

      if (typeof childObj === 'object') {
        result = this.findById(childObj, id);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }

  static getFlatNavigation(navigationItems, flatNavigation = []) {
    for (let i = 0; i < navigationItems.length; i += 1) {
      const navItem = navigationItems[i];

      if (navItem.type === 'item') {
        flatNavigation.push({
          id: navItem.id,
          title: navItem.title,
          type: navItem.type,
          icon: navItem.icon || false,
          url: navItem.url,
          auth: navItem.auth || null,
        });
      }

      if (navItem.type === 'collapse' || navItem.type === 'group') {
        if (navItem.children) {
          this.getFlatNavigation(navItem.children, flatNavigation);
        }
      }
    }
    return flatNavigation;
  }

  static difference(object, base) {
    function changes(_object, _base) {
      return transform(_object, (result, value, key) => {
        if (!isEqual(value, _base[key])) {
          result[key] =
            isObject(value) && isObject(_base[key]) ? changes(value, _base[key]) : value;
        }
      });
    }

    return changes(object, base);
  }

  static EventEmitter = EventEmitter;

  static updateNavItem(nav, id, item) {
    return nav.map((_item) => {
      if (_item.id === id) {
        return merge({}, _item, item);
      }

      if (_item.children) {
        return merge({}, _item, {
          children: this.updateNavItem(_item.children, id, item),
        });
      }

      return merge({}, _item);
    });
  }

  static removeNavItem(nav, id) {
    return nav
      .map((_item) => {
        if (_item.id === id) {
          return null;
        }

        if (_item.children) {
          return merge({}, omit(_item, ['children']), {
            children: this.removeNavItem(_item.children, id),
          });
        }

        return merge({}, _item);
      })
      .filter((s) => s);
  }

  static prependNavItem(nav, item, parentId) {
    if (!parentId) {
      return [item, ...nav];
    }

    return nav.map((_item) => {
      if (_item.id === parentId && _item.children) {
        return {
          ..._item,
          children: [item, ..._item.children],
        };
      }

      if (_item.children) {
        return merge({}, _item, {
          children: this.prependNavItem(_item.children, item, parentId),
        });
      }

      return merge({}, _item);
    });
  }

  static appendNavItem(nav, item, parentId) {
    if (!parentId) {
      return [...nav, item];
    }

    return nav.map((_item) => {
      if (_item.id === parentId && _item.children) {
        return {
          ..._item,
          children: [..._item.children, item],
        };
      }

      if (_item.children) {
        return merge({}, _item, {
          children: this.appendNavItem(_item.children, item, parentId),
        });
      }

      return merge({}, _item);
    });
  }

  static hasPermission(authArr, userRole) {
    if (authArr === null || authArr === undefined) {
      return true;
    }
    if (authArr.length === 0) {
    
      return !userRole || userRole.length === 0;
    }
    if (userRole && Array.isArray(userRole)) {
      return authArr.some((r) => userRole.indexOf(r) >= 0);
    }
    return authArr.includes(userRole);
  }

  static filterRecursive(data, predicate) {
    // if no data is sent in, return null, otherwise transform the data
    return !data
      ? null
      : data.reduce((list, entry) => {
          let clone = null;
          if (predicate(entry)) {
            // if the object matches the filter, clone it as it is
            clone = { ...entry };
          }
          if (entry.children != null) {
            // if the object has childrens, filter the list of children
            const children = this.filterRecursive(entry.children, predicate);
            if (children.length > 0) {
              // if any of the children matches, clone the parent object, overwrite
              // the children list with the filtered list
              clone = { ...entry, children };
            }
          }

          // if there's a cloned object, push it to the output list
          if (clone) {
            list.push(clone);
          }
          return list;
        }, []);
  }
}

export default FuseUtils;
