import _ from "lodash";

const selectedValues = [
  "name",
  "email",
  "gender",
  "pronouns",
  "logoImage",
  "description",
  "categorySpecialismId",
  "coachCategoryId",
  "resources",
  "accreditations",
];
export const useCoachEditProfileModel = (user) => {
  if (!user) {
    return {};
  }
  const getDefinedData = _(user)
    .pick(selectedValues)
    .mapValues((value) => (_.isNull(value) ? "" : value))
    .value();
  return getDefinedData;
};
