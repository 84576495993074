import { useState } from "react";
import LayoutContext from "./LayoutContext";

const LayoutProvider = ({ children }) => {
    const [layoutState, setLayoutState] = useState({});
    
    // Function to update the title
    const updateLayoutState = (newState) => {
        setLayoutState(newState);
    };
  
    return (
      <LayoutContext.Provider value={{ layoutState, updateLayoutState }}>
        {children}
      </LayoutContext.Provider>
    );
  };
  export default LayoutProvider