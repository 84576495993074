import { useCallback } from "react";

const useRequest = () =>
  useCallback(async (fn) => {
    const response = await fn();
    if (!response.statusText==="OK") {
      throw response;
    }
    const { data } = await response.data
    return data;
  }, []);

export default useRequest;
