import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useContext, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import AppContext from "src/app/context/AppContext";
import HeaderComponent from "src/app/views/components/header/HeaderComponent";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import FuseSuspense from "../FuseSuspense";
import Scrollbars from "react-custom-scrollbars-2";
import FooterComponent from "src/app/views/components/footer/FooterComponent";
import SideNavComponent from "src/app/views/components/header/SideNavComponent";
import LayoutContext from "src/app/context/LayoutContext";
import { useMediaQuery } from "react-responsive";
// import { AnimatePresence, motion } from "framer-motion";

const Layout = () => {
  // const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { layoutState: props } = useContext(LayoutContext);
  const {
    pageClassName,
    RightComponent,
    className,
    isBackHidden,
    isNavbarHidden,
    iconClassName,
    title,
    customFooter,
    SubHeader,
  } = props;
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const navigate = useNavigate();
  const [open, setOpen] = useState(!isMobile);
  const toggleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const goBack = useCallback(() => {
    typeof props.goBack === "function" ? props.goBack() : navigate(-1);
  }, [navigate, props]);
  const toolbarBackgroundColor = props.title
    ? "bg-sageColor"
    : "bg-transparent";
  const toolbarTextColor = props.title ? "text-white" : "text-darkColor";
  return (
    <div
      className={`dashboardWrapperComponent flex flex-col h-full justify-start items-start overflow-hidden ${
        className || ""
      }`}
    >
      <div className="sm:flex sm:flex-row-reverse h-full flex-1 w-full">
        <div className="flex flex-col h-full flex-1 w-full">
          <HeaderComponent isBarHidden={open} toggleClick={toggleClick} />

          <div
            className={`flex-1 overflow-y-hidden w-full min-h-0 flex flex-col p-2 md:p-4 lg:p-6 z-11 ${
              pageClassName || "bg-dividerColor"
            }`}
          >
            {SubHeader ? SubHeader : <></>}
            <Scrollbars style={{ height: "100%" }}>
              {!isBackHidden || title ? (
                <div
                  className={`dashboardBackTitleBar mb-4 z-20 px-[40px] md:justify-start text-center md:text-left relative h-[73px] flex justify-center items-center ${toolbarTextColor} ${toolbarBackgroundColor}`}
                >
                  {!isBackHidden ? (
                    <div
                      className="absolute left-[20px] cursor-pointer"
                      onClick={goBack}
                    >
                      <FontAwesomeIcon
                        icon={faLongArrowLeft}
                        className={
                          iconClassName || `${toolbarTextColor} text-[21px]`
                        }
                      />
                    </div>
                  ) : null}

                  {title ? (
                    <h1
                      className={`font-fraunces font-semibold text-[24px] ${toolbarTextColor} md:ml-4`}
                    >
                      {title || ""}
                    </h1>
                  ) : null}
                </div>
              ) : null}

              {RightComponent ? RightComponent : <></>}
              <div className="container mx-auto">
                {
                  <FuseSuspense>
                    {useRoutes(routes)}
                    {/* <AnimatePresence>
                      <motion.div
                        key={pathname}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                      >
                        {useRoutes(routes)}
                      </motion.div>
                    </AnimatePresence> */}
                  </FuseSuspense>
                }
              </div>
            </Scrollbars>
          </div>

          <FooterComponent customFooter={customFooter} />
        </div>
        {isNavbarHidden ? null : (
          <SideNavComponent open={open} onClose={onClose} />
        )}
      </div>
    </div>
  );
};
export default Layout;
