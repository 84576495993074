import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CheckboxComponent = (props) => {
  return (
    <div className={`checkboxComponent -mt[2px] ${props.className || ""}`}>
      <input
        type="checkbox"
        name={props.name}
        id={props.id}
        {...(typeof props.checked !== "undefined" && {
          checked: props.checked,
        })}
        {...(typeof props.onChange !== "undefined" && {
          onChange: props.onChange,
        })}
        {...(typeof props.disabled !== "undefined" && {
          disabled: props.disabled,
        })}
        {...props.checkbox}
      />
      <div
        className={`checkboxCustomBox ${
          props.boxClassName ? props.boxClassName : ""
        }`}
      >
        <FontAwesomeIcon icon={faCheck} />
      </div>
    </div>
  );
};

CheckboxComponent.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  boxClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  key: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CheckboxComponent;
