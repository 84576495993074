import { Provider } from "react-redux";
import AppContext from "./context/AppContext";
import routes from "./auth/configs/routesConfig";
import store from "./store";
const withAppProviders = (Component) => (props) => {
  function WrapperComponent() {
    return (
      <AppContext.Provider
        value={{
          routes
        }}
      >
          <Provider store={store}>
              <Component {...props} />
          </Provider>
      </AppContext.Provider>
    );
  }

  return WrapperComponent;
};

export default withAppProviders;
