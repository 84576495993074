import { useEffect } from "react";
import axios from "axios";
import axiosWithSnackbar from "./axiosWithSnackbar";
import JwtService from "src/app/auth/services/jwtService";
import { useLocation } from "react-router-dom";

const useAxiosSetup = () => {
  const location = useLocation();
  // Set Base URL
  const setBaseUrl = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    // Assuming axiosWithSnackbar is a custom instance of axios
    axiosWithSnackbar.defaults.baseURL = process.env.REACT_APP_API_URL;
  };

  // Set Snackbar Interceptors
  const setSnackbarInterceptors = () => {
    try {
      axiosWithSnackbar.interceptors.response.use(
        (response) => {
          JwtService.emit("onSnackbarMessage", response);
          return response;
        },
        (error) => {
          JwtService.emit("onSnackbarMessage", error);
          if (
            error?.response?.status === 401 &&
            !error?.config?.__isRetryRequest
          ) {
            if (location.pathname !== "/login") {
              JwtService.emit("onLogout");
            }
            return Promise.reject(error.response);
          }
          return Promise.reject(error);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  // Set Default Axios Interceptors
  const setInterceptors = () => {
    try {
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (
            error.response?.status === 401 &&
            !error.config?.__isRetryRequest
          ) {
            JwtService.emit("onLogout");
          }
          return Promise.reject(error);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  // Initialize the setup on component mount
  useEffect(() => {
    setBaseUrl();
    setSnackbarInterceptors();
    setInterceptors();
  }, []); // Re-run if apiUrl changes
};

export default useAxiosSetup;
