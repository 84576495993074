import axios from 'axios';
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";
import { getBaseSearchParams } from "src/helpers/utils";

export const fetchCategories = (searchParams = getBaseSearchParams()) => {
  return axios.get(`/coach/categories?${searchParams.toString()}`);
};

export const fetchAssignedCategories = (searchParams = getBaseSearchParams()) => {
  return axios.get(`/coach/categories/assigned?${searchParams.toString()}`);
};

export const fetchCategoryById = (categoryId) => {
  return axios.get(`/coach/category/${categoryId}`);
};

export const assignCategory = ({ id }) => {
  return axiosWithSnackbar.post(`/coach/category/assign`, {
    coach_category_id: id,
  });
};

export const removeCategory = ({ id }) => {
  return axios.post(`/coach/category/remove`, {
    coach_category_id: id,
  });
};
