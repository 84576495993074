import { v4 } from "uuid";

export const isAbsolute = (url) => /^([a-z][a-z\d\+\-\.]*:)?\/\//i.test(url);
// /^(https?:)\/\/([^/]+)/i

export const getOrigin = () =>
  `https://${process.env.REACT_APP_AWS_BUCKET}.s3.amazonaws.com`;

export const url = (path = "") => {
  if (path && !isAbsolute(path)) {
    return `${getOrigin()}/${path.startsWith("/") ? path.slice(1) : path}`;
  }

  return path;
};

export const getAssetUrl = ({
  id,
  dir = "coachDocuments",
  type = "resource",
}) => [dir, id, type, v4()].filter(Boolean).join("/");
