
const settingsConfig = {
  layout: {
    style: 'layout2', // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  /*
   To make whole app auth protected by default set defaultAuth:['administrator','staff','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */
  defaultAuth: null,
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: '/',
  // userDefaultRoute:{
  //   administrator:"/",
  //   staff:"/",
  //   user:"/"
  // }
};

export default settingsConfig;
