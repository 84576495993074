import { lazy } from "react";

const ResetPasswordView = lazy(() => import("src/app/views/ResetPasswordView"));
const PasswordResetView = lazy(() => import("src/app/views/PasswordResetView"));
const LoginView = lazy(() => import("src/app/views/LoginView"));

const SignInConfig = {
  routes: [
    {
      path: "login",
      element: <LoginView />,
      // element: <h1>Login</h1>,
    },
    {
      path: "forgot-password",
      element: <ResetPasswordView />,
    },
    {
      path: "reset-password/:token",
      element: <PasswordResetView />,
    },
  ],
};

export default SignInConfig;
