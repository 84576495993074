import axios from 'axios';

import { getBaseHeaders } from "src/helpers/utils";

const url = `${process.env.REACT_APP_API_URL}/coach`;



export const fetchCoach = () => {
  return axios.get('/coach/my');
};
export const updateCoach = (coachProfile) =>
  fetch(`${url}/my`, {
    method: "PATCH",
    body: JSON.stringify(coachProfile),
    headers: getBaseHeaders(),
  });
