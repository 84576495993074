import { useDispatch, useSelector } from "react-redux";
import { COACHES_DOMAIN } from "src/const";
import { useEffect } from "react";
import { coachGetMyDetailsActions, userGetMyDetailsActions } from "src/app/store/actions/userActions";
import useDomain from "./generalHooks/useDomain";

const useInitialize=()=>{
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userReducer.userDetails);
    const currentUser = useSelector((state) => state.userReducer.currentUser);
    const domainName = useDomain();
    useEffect(() => {
      const fetchUser = async () => {
        const isCoach = domainName === COACHES_DOMAIN;
        if (currentUser?.initial) {
          if (isCoach) {
            await coachGetMyDetailsActions(dispatch, userDetails);
          }
          else
          {
            await userGetMyDetailsActions(dispatch, userDetails);
          }
        }
      };
      fetchUser();
    }, [currentUser?.initial, dispatch, userDetails]);
}
export default useInitialize