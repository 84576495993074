import { combineReducers } from "redux";
import userReducer from "./userReducer";
import snackbarReducer from "./snackbarReducer";
import employeeCoachReducer from "./employee/employeeCoachReducer";
import employeeCheckoutReducer from "./employee/employeeCheckoutReducer";
import onboardingReducer from "./onboardingReducer";
import employeeAssessmentReducer from "./employee/employeeAssessmentReducer";
// need to create Error handler for all apis a general reducer which will help dev to handle ALL api's responses
const rootReducer = combineReducers({
  snackbarReducer,
  userReducer,
  employeeCoachReducer,
  employeeCheckoutReducer,
  onboarding: onboardingReducer,
  employeeAssessmentReducer,
});

export default rootReducer;
