import { memo, useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DailyIframe from "@daily-co/daily-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import useSnackbarMessage from "../core/Snackbar/useSnackbarMessage";
import { fetchBookedAppointmentByToken } from "../store/apis/employee/employeeAppointmentApi";
import useSetLayoutHook from "./hooks/generalHooks/useSetLayoutHook";
import { fetchCoachAppointmentByToken } from "../store/apis/coach/appointments";
// import useDomain from "./hooks/generalHooks/useDomain";
// import { COACHES_DOMAIN } from "src/const";
import useDomain from "./hooks/generalHooks/useDomain";
import { COACHES_DOMAIN } from "src/const";

const fetchAppointmentService = async (eventId) => {
  const response = await fetchBookedAppointmentByToken(eventId);
  const data = await response.data;
  return data;
};
const fetchCoachAppointmentService = async (eventId) => {
  const response = await fetchCoachAppointmentByToken(eventId);
  const data = await response.data;
  return data;
};

function AppointmentMeeting() {
  const domainName = useDomain();

  const isCoach = domainName === COACHES_DOMAIN;
  const layoutProps = useMemo(
    () => ({
      isNavbarHidden: true,
      isBackHidden: true,
    }),
    []
  );
  useSetLayoutHook(layoutProps);
  const navigate = useNavigate();
  const { appointmentToken } = useParams();
  const [isBackButtonVisible, setBackButtonVisibility] = useState(false);
  const setSnackbarMessage = useSnackbarMessage();

  const [meetingInstance] = useState(() => {
    const callFrameInstance = DailyIframe.createFrame({
      iframeStyle: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        left: 0,
        bottom: 0,
        zIndex: 10,
      },
    });

    const joinMeeting = () => {
      displayBackButtonControl();
    };
    const leaveMeeting = () => {
      displayBackButtonControl();
    };
    const displayBackButtonControl = () => {
      setBackButtonVisibility(true);
    };
    return callFrameInstance
      .on("joining-meeting", joinMeeting)
      .on("left-meeting", leaveMeeting)
      .on("error", displayBackButtonControl);
  });
  const meetingInstanceRef = useRef(meetingInstance);

  useEffect(() => {
    if (!appointmentToken) {
      return;
    }

    let isComponentActive = true;
    const fetchData = async () => {
      try {
        const response = isCoach
          ? await fetchCoachAppointmentService(appointmentToken)
          : await fetchAppointmentService(appointmentToken);
        if (!response?.data) {
          throw new Error("Problem with response");
        }

        const appointmentRoomUrl = response?.data?.appointment_room?.url;

        if (!appointmentRoomUrl) {
          throw new Error(
            "Appointment room not available for this appointment"
          );
        }

        if (isComponentActive) {
          meetingInstanceRef.current.join({
            url: appointmentRoomUrl,
          });
        }
      } catch (e) {
        setSnackbarMessage(e.message, "error");
        navigate("/");
      }
    };

    fetchData();

    // eslint-disable-next-line consistent-return
    return () => {
      if (meetingInstanceRef?.current) {
        isComponentActive = false;
        meetingInstanceRef?.current.destroy();
      }
    };
  }, [appointmentToken, navigate]);
  const goBack = () => {
    if (meetingInstanceRef.current.meetingState() === "joined-meeting") {
      meetingInstanceRef.current.leave();
      return;
    }
    navigate("/");
  };

  // TODO: add any additional ui elements
  return (
    isBackButtonVisible && (
      <button className="fixed left-[20px] z-10 color-white" onClick={goBack}>
        <FontAwesomeIcon icon={faLongArrowLeft} />
      </button>
    )
  );
}

export default memo(AppointmentMeeting);
