/* eslint-disable class-methods-use-this */
import axios from 'axios';
// import Cookies from 'js-cookie';
import jwtServiceConfig, { USER_ROLE, USER_TOKEN } from './jwtServiceConfig';
import { users } from '../../authRoles';
import FuseUtils from "src/app/core/utils/FuseUtils";
import { ACCOUNT_PENDING_ERROR } from "src/const";
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  handleAuthentication = () => {
    const user = this.getAccessToken() || {}
    const {access_token}=user
    const user_role = this.getUserRole()
    if (!access_token || !user_role) {
      this.emit('onNoAccessToken');
      this.emit('onAutoLogout', '');
    } else {

      this.emit('onAutoLogin',{...user,role:user_role});
    }
  };

  setTokenHeader = (token) => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      axiosWithSnackbar.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
      delete axiosWithSnackbar.defaults.headers.common.Authorization;
    }
  };

  handleSSORedirect = (type, code) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/login/${type}/callback?code=${code}`)
        .then((response) => {
          if (response.data) {
            const { access_token, token_expiry_minutes } = response.data;
            const token = { access_token, token_expiry_minutes };
            this.setSession(token);
            this.emit('onAutoLogin', true);
            resolve(response.data);
          }
        })
        .catch((err) => {
          const { error } = err.response.data;
          if (error.startsWith('User not found')) {
            const email = error.split(':')[1];
            this.createUser({ email, name: email.split('@')[0] }).then((res) => {
              this.emit('onSSOUserCreated', res);
              resolve(res);
            });
          } else if (error === ACCOUNT_PENDING_ERROR) {
            resolve('onSSOUserPending');
          }
        });
    });
  };

  getUserData = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.getUser}/${id}`)
        .then((response) => {
          // we will need it in future. Handling will depend on what we want to do with the response.
          // if (response.data.data) {
          // }
        })
        .catch((err) => console.log(err, 'error data'));
    });
  };

  getCurrentUserData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${jwtServiceConfig.getCurrentUser}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  createUser = (data) => {
    if (this.getUserInStorage() === users.agent) {
      data.availability_settings = {};
    }
    if (this.getUserInStorage() === users.entrepreneur) {
      data.current_roadmap_step = 1;
    }
    return new Promise((resolve, reject) => {
      axiosWithSnackbar
        .post(jwtServiceConfig.signUp[this.getUserInStorage()], data)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => reject(this.rejectWithError(err)));
    });
  };

  ssoLogin = (param) => {
    return new Promise((resolve, reject) => {
      axios
        .get(jwtServiceConfig.sso[param], {})
        .then((response) => {
          window.location.href = response.data.redirect_url;
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  signInWithCredentials = ({ email, password, remember }, otp_code) => {
    return new Promise((resolve, reject) => {
      axiosWithSnackbar
        .post(jwtServiceConfig.signIn, {
          username: email,
          password,
          otp_code,
        })
        .then((response) => {
          if (response && response.data) {
            const { access_token, token_expiry_minutes } = response.data;
            const token = { access_token, token_expiry_minutes };
            this.setTokenHeader(access_token);
            this.getCurrentUserData()
              .then((user) => {
                const userWithRole = { ...user, role: user.roles[0] };
                if (userWithRole.role === 'emg-expert') {
                  userWithRole.role = 'emg_expert';
                  user.roles[0] = 'emg_expert';
                }
                resolve(userWithRole);
                this.emit('onLogin', userWithRole);
                // non persistent session
                if (!remember) {
                  token.token_expiry_minutes = null;
                }
                this.setSession(token);
              })
              .catch((err) => console.log(err, 'error getting current user'));
          }
        })
        .catch((err) => {
          reject(err.data.error);
        });
    });

    // .then((response) => {
    //   if (response && response.data) {
    //     const { access_token, token_expiry_minutes } = response.data;
    //     const token = { access_token, token_expiry_minutes };
    //     this.setTokenHeader(access_token);
    //     return this.getCurrentUserData().then((user) => {
    //       const userWithRole = { ...user, role: user.roles[0] };
    //       this.emit('onLogin', userWithRole);
    //       // non persistent session
    //       if (!remember) {
    //         token.token_expiry_minutes = null;
    //       }
    //       this.setSession(token);
    //       return userWithRole;
    //     });
    //   }
    //   console.log(response, 'response');
    //   return response;
    // })
    // .catch((err) => {
    //   console.log(err, 'err');
    //   return Promise.reject(err);
    //   // you could also throw the error again, so it can be caught and handled by the code that calls this function
    //   // throw err;
    // });
  };

  updateUserData = (user) => {
    return new Promise((resolve, reject) => {
      axiosWithSnackbar
        .patch(jwtServiceConfig.updateUser, user.data)
        .then((res) => {
          if (res.data.data) {
            resolve(res.data.data);
            this.emit('onUserUpdated', res.data.data);
          } else {
            reject('Error');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  setSession = (token) => {
    const { access_token, token_expiry_minutes,role} = token;
    if (access_token) {
      this.setTokenHeader(access_token);
      this.setAccessToken(USER_TOKEN, access_token, token_expiry_minutes);
      this.setLocalStorageItem(USER_ROLE,role)
    } else {
      this.setTokenHeader();
    }
  };

  logout = () => {
    this.removeAccessToken();
    this.emit('onLogout', 'Signed out');
  };
  setAccessToken = (tokenName, data, expiryMinutes) => {
    // expiry minutes converted to days
    this.setLocalStorageItem(tokenName,JSON.stringify(data))
    // Cookies.set(name, data, {
    //   ...(expiryMinutes ? { expires: expiryMinutes / (24 * 60) } : {}),
    // });
  };

  setLocalStorageItem=(name,value)=>
  {
    localStorage.setItem(name,value)
  }
  getLocalStorageItem=(name)=>
  {
    return localStorage.getItem(name)
  } 
  removeLocalStorageItem=(name)=>
  {
    localStorage.setItem(name)
  }

  getAccessToken = () => {
    return JSON.parse(this.getLocalStorageItem(USER_TOKEN))
    // return Cookies.get(USER_TOKEN);
  };

  getUserRole = () => {
    return this.getLocalStorageItem(USER_ROLE)
    // return Cookies.get(USER_TOKEN);
  };
  setUserRole = (role) => {
    return this.setLocalStorageItem(USER_ROLE,role)
    // return Cookies.get(USER_TOKEN);
  };

  removeUserRole = () => {
    return this.removeLocalStorageItem(USER_ROLE)
    // return Cookies.get(USER_TOKEN);
  };

  removeAccessToken = () => {
    return this.removeLocalStorageItem(USER_TOKEN)
    // return Cookies.remove(USER_TOKEN);
  };

  setUserInStorage = (userType) => {
    this.setLocalStorageItem("user_type",userType)
  };

  getUserInStorage = () => {
    return this.getLocalStorageItem("user_type")
  };
}

const instance = new JwtService();
export { axiosWithSnackbar };
export default instance;
