import React, { memo } from "react";

// images
import logoDark from "../../assets/logo_dark.png";
import splashHero from "../../assets/splash_hero.png";
import BouncingLoader from "./components/UI/BouncingLoader";

const SplashView = () => {
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     window.location.reload();
  //   }, 3000);
  //   return () => clearTimeout(timeoutId);
  // }, []);

  return (
    <div className="splashView h-full relative px-[40px] flex flex-row items-start justify-center">
      <div className="absolute bottom-0 left-0 right-0 h-[55%] flex items-end justify-center overflow-hidden">
        <img src={splashHero} alt="splash hero" className="max-h-full" />
      </div>
      <div className="relative z-1 h-[45%] flex flex-col justify-end items-end max-w-[400px]">
        <img src={logoDark} alt="splash logo" className="mb-[5%]" />
        <BouncingLoader />
      </div>
    </div>
  );
};

export default memo(SplashView);
