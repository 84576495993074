import { HIDE_STATUS, SHOW_STATUS } from "../actionTypes/snackbarActionTypes";

// Initial State
const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 5000,
    message: 'Hi',
    variant: "info",
  },
};

// Reducer
const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_STATUS:
      return {
        ...state,
        state: true,
        options: {
          ...initialState.options,
          ...action.payload,
        },
      };
    case HIDE_STATUS:
      return {
        ...state,
        state: null,
      };
    default:
      return state;
  }
};

export default snackbarReducer;
