import { isEmpty } from "lodash";
import { USER_TOKEN } from "src/app/auth/services/jwtService/jwtServiceConfig";
import { utils } from "src/helpers/utils";

const failed = (e) => {
  return {
    initial: false,
    pending: false,
    failed: true,
    message: "Failed...",
    todo: {
      data: e?.data || e?.errors || null,
      error: e?.message || e?.error || "Something Went Wrong",
    },
  };
};

const successor = (response) => {
  return {
    initial: false,
    pending: false,
    failed: false,
    message: "Success...",
    todo: {
      data: response.data || response,
      error: response.message || response.error || null,
    },
  };
};

const getAuth = (userDetails = {}) => {
  const getUserFromCache = userDetails.todo?.data || utils.getCache(USER_TOKEN);
  if (!isEmpty(getUserFromCache)) {
    const auth = `${getUserFromCache.token_type} ${getUserFromCache.access_token}`;
    return auth;
  }
  return null;
};

const responseContainer = {
  failed,
  getAuth,
  successor,
  
};
export default responseContainer;
