import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/";

const initialState = {};
const middleWare = [thunk];

// Customize DevTools options
const devToolsOptions = {
  // Trace is ON by default. You can set a trace limit here if needed.
  trace: true,
  traceLimit: 25 // for example, limit to last 25 actions
};

const middlewareEnhancer = applyMiddleware(...middleWare);

// Update this part to enable trace
const composedEnhancers = composeWithDevTools({
  // Options: see https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
  trace: true, // Enable trace feature
  traceLimit: 25 // Limit the number of actions to trace (optional)
})(middlewareEnhancer);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store;
