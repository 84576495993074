import userAvatar from "src/assets/user_avatar.png";

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useCompanyDashboardHook = () => {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const updateCurrentUser = useSelector(
    (state) => state.userReducer.updateCurrentUser
  );

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.pending) {
        setUser(currentUser.todo.data);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (updateCurrentUser) {
    }
  }, [updateCurrentUser]);

  // const toTokenView = useCallback(() => {
  //   navigate("/company/token");
  // }, [navigate]);

  const getHeaderCoins = useCallback(() => {
    return user?.professionalBalance && user?.privateBalance
      ? Number.parseInt(user?.professionalBalance) +
          Number.parseInt(user?.privateBalance)
      : 0;
  }, [user?.privateBalance, user?.professionalBalance]);

  const getHeaderImage = useCallback(() => {
    if (user) {
      return user?.tempUrl || user?.profileImage || userAvatar;
    }
    return userAvatar;
  }, [user]);

  const getProfileRoute = useMemo(() => {
    return "/company/profile/edit";
  }, []);

  return {
    user,
    getHeaderCoins,
    getHeaderImage,
    getProfileRoute,
  };
};

export default useCompanyDashboardHook;
