import { useNavigate } from "react-router-dom";
import userAvatar from "src/assets/user_avatar.png";

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useCoachEditProfileModel as coachEditProfileModel } from "src/app/views/models/coachModels/useCoachEditProfileModel";

const useCoacheDashboardHook = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const updateCurrentUser = useSelector(
    (state) => state.userReducer.updateCurrentUser
  );

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.pending) {
        setUser(currentUser.todo.data);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (updateCurrentUser) {
    }
  }, [updateCurrentUser]);

  const toTokenView = useCallback(() => {
    navigate("/coaches/tokenSummary");
  }, [navigate]);

  const getHeaderCoins = useCallback(() => {
    return user?.balance ? Number.parseInt(user?.balance) : 0;
  }, [user?.balance]);

  const getHeaderImage = useCallback(() => {
    if (user?.logoImage) {
      return user?.tempUrl || user?.logoImage || userAvatar;
    }
    return userAvatar;
  }, [user?.logoImage, user?.tempUrl]);

  const getProfileRoute = useMemo(() => {
    return "/coaches/profile/edit/0";
  }, []);
  const getProfileEditRoute = useMemo(() => "/coaches/profile/edit", []);

  const parsedUser = useMemo(() => coachEditProfileModel(user), [user]);

  return {
    getProfileEditRoute,
    user,
    getHeaderCoins,
    getHeaderImage,
    toTokenView,
    getProfileRoute,
    parsedUser,
  };
};

export default useCoacheDashboardHook;
