import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideMessage } from "src/app/store/actions/snackbarActions";
import "./FuseMessage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClose,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
function setPosition(element, vertical, horizontal) {
  element?.classList.add(`${vertical}-${horizontal}`);
}
function setVariant(element, variant) {
  element?.classList.add(`${variant}`);
}

const variantIcon = {
  success: faCheckCircle,
  warning: faExclamationCircle,
  error: faClose,
  info: faInfoCircle,
};

function FuseMessage(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.snackbarReducer.state);
  const options = useSelector((state) => state.snackbarReducer.options);

  useEffect(() => {
    const element = document.getElementById("snackbar-content");
    setPosition(
      element,
      options.anchorOrigin.vertical,
      options.anchorOrigin.horizontal
    );
    setVariant(element, options.variant);
    const messageTimeout = setTimeout(() => {
      dispatch(hideMessage());
    }, [options.autoHideDuration]);

    return () => clearTimeout(messageTimeout);
  }, [options]);

  return state ? (
    <div
      className="z-50 flex items-center bg-gray-800 text-white px-5 py-3 rounded-lg shadow-md"
      id="snackbar-content"
    >
      <FontAwesomeIcon icon={variantIcon[options.variant]} />
      {/* {variantIcon[options.variant] && (
      <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
      // <FuseSvgIcon className="text-current">
      //   {variantIcon[options.variant]}
      // </FuseSvgIcon>
    )} */}
      <h3 className="ml-2 text-sm md:text-base">{options.message}</h3>
    </div>
  ) : null;
}

export default memo(FuseMessage);
