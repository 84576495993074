import { useCallback, useState } from "react";
import { useEffect } from "react";
import { memo } from "react";
import { createContext } from "react";
import { Outlet } from "react-router-dom";
import { fetchAppointmentTypes } from "src/app/store/apis/coach/appointment-types";
import { fetchCoachAppointments } from "src/app/store/apis/coach/appointments";
import { fetchAssignedCategories } from "src/app/store/apis/coach/categories";
import { fetchCoach } from "src/app/store/apis/coach/my";
import { fetchResources } from "src/app/store/apis/coach/resourcesAndAccreditations";
import { fetchAssignedSpecialisms } from "src/app/store/apis/coach/specialisms";
import useRequest from "../hooks/coachHooks/useRequest";
import useInitialize from "../hooks/useInitialize";

export const CoachAttributes = {
  Sectors: "sectors",
  Specialisms: "specialisms",
  Accreditations: "accreditations",
  AppointmentTypes: "appointment-types",
  CoachDetails: "coach-details",
  Appointments: "appointments",
};

const coach = {
  [CoachAttributes.Sectors]: null,
  [CoachAttributes.Specialisms]: null,
  [CoachAttributes.Accreditations]: null,
  [CoachAttributes.AppointmentTypes]: null,
  [CoachAttributes.CoachDetails]: null,
  [CoachAttributes.Appointments]: null,
};

export const CoachContext = createContext(coach);

const Coach = () => {
  const [coachState, setCoachState] = useState(Coach.data);
  const request = useRequest();
  useInitialize();
  const reFetchCoach = useCallback(async () => {
    const key = 4;
    return request(Coach.api.get(key)).then((result) => {
      setCoachState({ ...Coach.data, [CoachAttributes.CoachDetails]: result });
    });
  }, [request]);

  useEffect(() => {
    Promise.allSettled(Array.from(Coach.api.values()).map(request)).then(
      (result) => {
        const coachAttributes = [
          CoachAttributes.Sectors,
          CoachAttributes.Specialisms,
          CoachAttributes.Accreditations,
          CoachAttributes.AppointmentTypes,
          CoachAttributes.CoachDetails,
          CoachAttributes.Appointments,
        ];

        Coach.data = result.reduce(
          (acc, { status, value }, index) => {
            if (status !== "fulfilled") {
              return acc;
            }

            // Coach.api.delete(index);
            return {
              ...acc,
              [coachAttributes[index]]: value,
            };
          },
          { ...Coach.data }
        );

        setCoachState(Coach.data);
      }
    );
  }, []);

  return (
    <CoachContext.Provider
      value={[coachState, setCoachState, { reFetchCoach }]}
    >
      <Outlet />
    </CoachContext.Provider>
  );
};

Coach.api = new Map()
  .set(0, fetchAssignedCategories)
  .set(1, fetchAssignedSpecialisms)
  .set(2, fetchResources)
  .set(3, fetchAppointmentTypes)
  .set(4, fetchCoach)
  .set(5, fetchCoachAppointments);

Coach.data = {
  ...coach,
};

export default memo(Coach);
