export default function userReducer(
  state = {
    currentUser: {
      initial: true,
      pending: true,
      failed: false,
      message: "Pending...",
      todo: {
        data: null,
        error: null,
      },
    },
  },
  action
) {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        userDetails:action.data,
      };
    }
    case "CURRENT_USER": {
      return {
        ...state,
        currentUser: action.data,
      };
    }
    case "UPDATE_CURRENT_USER": {
      return {
        ...state,
        updateCurrentUser: action.data,
      };
    }
    case "COACH_SIGNUP": {
      return {
        ...state,
        coachSignedUpUser: action.data,
      };
    }
    case "LOGOUT": {
      return {...state, logout: true };
    }
    default:
      return state;
  }
}
