import "../../../style/components/bouncingLoader.scss";
const BouncingLoader = () => {
  return (
    <div
      className="self-center mb-[5%]"
      id="spinner"
      sx={{
        "& > div": {
          backgroundColor: "palette.secondary.main",
        },
      }}
    >
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  );
};
export default BouncingLoader;
