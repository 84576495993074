export const ONBOARDING_UPDATE_BOOTSTRAP_DATA =
  "ONBOARDING_UPDATE_BOOTSTRAP_DATA";
export const ONBOARDING_UPDATE_FORM_DATA = "ONBOARDING_UPDATE_FORM_DATA";
export const ONBOARDING_CLEANUP_DATA = "ONBOARDING_CLEANUP_DATA";

export const updateAction =
  (payload = {}, type) =>
  (dispatch) => {
    dispatch({
      type,
      payload,
    });
  };
