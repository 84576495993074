import React, { lazy, Suspense } from "react";
const LazyLink = lazy(() => import("./LazyLink"));
// Define a map of button types to CSS classes
const buttonClasses = {
  primary: "bg-blue-700 hover:bg-blue-900 text-white py-2 px-4 rounded",
  secondary: "bg-violet-500 hover:bg-violet-700 text-white py-2 px-4 rounded",
  success: "bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded",
  danger: "bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded",
  // Add more button types as needed
};
const disabledClasses = "bg-gray-300 cursor-not-allowed opacity-50";
const StyledButton = ({
  children,
  className,
  isRounded,
  title,
  disabled,
  onClick,
  href,
  isStretch,
  buttonType = "primary",
  type = "button",
  ...props
}) => {
  // Get the CSS class for the specified type or use the default class
  const buttonClass = buttonClasses[buttonType] || buttonClasses.primary;

  return href ? (
    <Suspense fallback={<button className="text-gray-500">Loading...</button>}>
      <LazyLink
        to={href}
        className={`font-commissionerMedium min-w-[100px] text-center flex-grow ${
          isStretch ? "" : "md:flex-grow-0"
        } ${buttonClass} ${className} ${isRounded ? "rounded-full" : ""} ${
          disabled ? disabledClasses : ""
        }`}
        {...props}
      >
        {children || title}
      </LazyLink>
    </Suspense>
  ) : (
    <button
      type={type}
      onClick={disabled ? () => {} : onClick}
      className={`font-commissionerMedium min-w-[100px] flex-grow ${
        isStretch ? "" : "md:flex-grow-0"
      } ${buttonClass} ${className} ${isRounded ? "rounded-full" : ""} ${
        disabled ? disabledClasses : ""
      }`}
      {...props}
    >
      {children || title}
    </button>
  );
};

export default StyledButton;
