export default function employeeCoachReducer(
  state = {
    coachesList: {
      initial: true,
      pending: true,
      failed: false,
      message: "Pending...",
      todo: {
        data: null,
        error: null,
      },
    },
  },
  action
) {
  switch (action.type) {
    case "COACHES_LIST": {
      return {
        ...state,
        coachesList: action.data,
      };
    }

    case "COACHES_BY_FILTER_LIST": {
      return {
        ...state,
        coachesListByFilter: action.data,
      };
    }

    case "SPECIALISM_LIST": {
      return {
        ...state,
        specialismList: action.data,
      };
    }

    default:
      return state;
  }
}
