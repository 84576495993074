import { useNavigate } from "react-router-dom";
import userAvatar from "src/assets/user_avatar.png";

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const useEmployeeDashboardHook = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const coachesList = useSelector(
    (state) => state.employeeCoachReducer.coachesList
  );

  const updateCurrentUser = useSelector(
    (state) => state.userReducer.updateCurrentUser
  );

  const [user, setUser] = useState({});
  const [coachesListForView, setCoachesListForView] = useState([]);

  useEffect(() => {
    if (coachesList) {
      if (coachesList.failed) {
        return;
      }
      if (!coachesList.pending) {
        setCoachesListForView(coachesList.todo.data);
      }
    }
  }, [coachesList]);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.pending) {
        setUser(currentUser.todo.data);
      }
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (updateCurrentUser) {
    }
  }, [updateCurrentUser]);

  const toTokenView = useCallback(() => {
    navigate("/employee/token");
  }, [navigate]);

  const getHeaderCoins = useCallback(() => {
    return user?.professionalBalance && user?.privateBalance
      ? Number.parseInt(user?.professionalBalance) +
          Number.parseInt(user?.privateBalance)
      : 0;
  }, [user?.privateBalance, user?.professionalBalance]);

  const getHeaderImage = useCallback(() => {
    if (user) {
      console.log(user, "user");
      return user?.tempUrl || user?.profileImage || userAvatar;
    }
    return userAvatar;
  }, [user]);

  const getProfileRoute = useMemo(() => {
    return "/employee/profile/edit";
  }, []);

  const getSearchRoute = useMemo(() => "/employee/search", []);

  return {
    user,
    getHeaderCoins,
    getHeaderImage,
    toTokenView,
    getProfileRoute,
    coachesListForView,
    getSearchRoute,
  };
};

export default useEmployeeDashboardHook;
