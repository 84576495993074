import clsx from "clsx";
import CheckboxComponent from "../../UI/CheckboxComponent";

const { default: useFormFields } = require("./useFormFields");
const handleCheckboxChange = (e, field, formik) => {
  if (e.target.checked) {
    formik.setFieldValue(field.name, [
      ...formik.values[field.name],
      e.target.value,
    ]);
  } else {
    formik.setFieldValue(
      field.name,
      formik.values[field.name].filter((value) => value !== e.target.value)
    );
  }
};

const className = clsx([
  "h-[37px]",
  "md:h-[44px]",
  "w-[100%]",
  "border-[1px]",
  "border-solid",
  "border-formBordersColor",
  "focus:ring-0",
  "focus:border-formBordersColor",
  "active:border-formBordersColor",
  "focus:shadow-sm",
  "active:shadow-sm",
  "focus:outline-none",
  "active:outline-none",
  "rounded-[7px]",
  "text-customGreyColor",
  "text-[16px]",
  "px-3",
  "whitespace-pre-wrap",
]);

const InputField = ({ field, formik }) => {
  switch (field.type) {
    case "select":
      return (
        <select
          className={className}
          name={field.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
        >
          {field.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    case "checkbox":
      return field.options.length ? (
        field.options.map((option) => (
          <label
            htmlFor={option.label}
            className="flex items-center cursor-pointer pr-2 leading-[40px] w-[90px]"
          >
            <input
              className="checkboxComponent -mt[2px] rounded mr-2"
              type="checkbox"
              name={option.label}
            />
            <h6 className="uppercase !text-[12px]">{option.label}</h6>
          </label>
        ))
      ) : (
        <label
          htmlFor={field.name}
          className="flex items-center cursor-pointer pr-2 leading-[40px] w-[90px]"
        >
          <input
            className="checkboxComponent -mt[2px] rounded mr-2"
            type="checkbox"
            name={field.name}
          />
          <h6 className="uppercase !text-[12px]">{field.name}</h6>
        </label>
      );
    case "radio":
      return (
        <input
          type={field.type}
          name={field.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          checked={formik.values[field.name]}
        />
      );
    case "date":
      return (
        <input
          className={className}
          type="date"
          name={field.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
        />
      );
    case "textarea":
      return (
        <textarea
          className={`${className} w-[100%] border-[1px] border-solid border-formBordersColor focus:ring-0 focus:border-formBordersColor active:border-formBordersColor focus:shadow-sm active:shadow-sm focus:outline-none active:outline-none rounded-[7px] text-customGreyColor text-[16px] resize-none h-[76px] md:h-[76px]`}
          name={field.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
        />
      );
    default:
      return (
        <input
          className={className}
          type={field.type || "text"}
          name={field.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[field.name]}
        />
      );
  }
};

const FormikForm = ({ fields, handleSubmit, children }) => {
  const formik = useFormFields(fields, handleSubmit);

  return (
    <form onSubmit={formik.handleSubmit}>
      {fields.map((field) => (
        <div key={field.name} className="flex flex-col mb-2">
          <label
            className="text-[14px] mb-1 font-commissionerMedium self-start"
            htmlFor={field.name}
          >
            {field.label}
          </label>
          <InputField field={field} formik={formik} />
          {formik.errors[field.name] && formik.touched[field.name] && (
            <p className="text-red-600 font-commissionerSemiBold italic text-right h-[15px]">
              {formik.errors[field.name]}
            </p>
          )}
        </div>
      ))}
      {children || null}
    </form>
  );
};

export default FormikForm;
