import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSnackbarMessage from "../core/Snackbar/useSnackbarMessage";
import JwtService from "./services/jwtService";
import SplashView from "../views/SplashView";
import {
  userGetMyDetailsActions,
  userLogoutActions,
} from "../store/actions/userActions";
import useAxiosSetup from "../core/FuseAuthorization/useAxiosSetup";
import { USER_ROLE } from "./services/jwtService/jwtServiceConfig";
import responseContainer from "../store/actions/utils/responseController";
import { userType } from "./authRoles";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();
const { successor } = responseContainer;

function AuthProvider({ children }) {
  useAxiosSetup();
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const setSnackbarMessage = useSnackbarMessage();

  useEffect(() => {
    const logout = async () => {
      await userLogoutActions(dispatch);
      window.location.href = "/login";
      // navigate("/login")
    };
    JwtService.on("onAutoLogin", async (user) => {
      JwtService.setTokenHeader(user.access_token);
      success(user, "Signed In");
      if (userType.isEmployee()) {
        userGetMyDetailsActions(dispatch, {});
      }
      // JwtService.getCurrentUserData().then((user) => {
      //   // CancelSubscription-Testing
      //   // user.stripe_subscription_status = null;
      //   success(user,"Signed In");
      // });
      /**
       * Sign in and retrieve user data with stored token
       */
    });
    JwtService.on("onSnackbarMessage", (res) => {
      const response = res?.response;
      if (
        (response?.status >= 200 && response?.status < 300) ||
        res?.status >= 200
      ) {
        setSnackbarMessage(
          response?.statusText || res?.statusText || "Success",
          "success"
        );
      } else {
        const error = response?.data?.error?.message || response?.data?.message;
        setSnackbarMessage(error || "Error", "error");
      }
      return res;
    });
    JwtService.on("onLogin", (user) => {
      success(user, "Signed in");
    });

    JwtService.on("onUserUpdated", (user) => {
      success(user, "Profile Updated");
    });
    JwtService.on("onSSOUserCreated", (user) => {
      setSnackbarMessage("User successfully created. You can sign in now");
    });

    JwtService.on("onLogout", (message) => {
      pass(message);
      logout();
    });

    JwtService.on("onAutoLogout", (message) => {
      pass(message);
      // logout();
      // dispatch(logoutUser());
    });

    JwtService.on("onNoAccessToken", () => {
      // if(window.location.pathname!=="/login")
      // {
      //   pass("Signed out");
      // }
    });
    function success(user, message) {
      //Temporary
      const userDataForStore = { ...successor(JwtService.getAccessToken()) };
      userDataForStore.todo.data.role = user.role;
      dispatch({ type: "LOGIN", data: userDataForStore });
      setWaitAuthCheck(false);
      setIsAuthenticated(true);
      //Think
      JwtService.setLocalStorageItem(USER_ROLE, user.role);

      if (message) {
        setSnackbarMessage(message);
      }
      // settingsConfig.loginRedirectUrl =user?.role ?userRedirects[user.role]: "/"

      // const userWithRole = { ...user, role: user.roles[0] };
      // // Promise.all([
      // //   // we haven't added the roles yet so by default its admin for now.
      // //   // tempComment:
      // //   dispatch(setUser(userWithRole)),
      // //   // You can receive data in here before app initialization
      // // ])
      // //   .then((values) => {
      // //     setWaitAuthCheck(false);
      // //     setIsAuthenticated(true);
      // //   })
      // //   .catch((err) => {
      // //     console.log(err, 'error loading');
      // //   });
      // // // agentDisable
      // // // if (userType.isEntrepreneur(user)) {
      // // //   dispatch(getAgent());
      // // // }
    }

    function pass(message) {
      if (message) {
        setSnackbarMessage(message);
      }
      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
    JwtService.handleAuthentication();
  }, [dispatch]);
  const isAuthenticatedProp = React.useMemo(
    () => ({ isAuthenticated }),
    [isAuthenticated]
  );
  return waitAuthCheck ? (
    <SplashView />
  ) : (
    <AuthContext.Provider value={isAuthenticatedProp}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
