const COACHES_DOMAIN="coaches"
const OTP_FAIL_ERROR = 'otpfail';
const ACCOUNT_PENDING_ERROR = 'Not yet approved';
const googleCalendarToken = 'google_calendar_access_token';
const microsoftCalendarToken = 'microsoft_calendar_access_token';
const MEETING_TYPES = {
  FACE: 'face-to-face',
  CHAT: 'live-chat',
  VOICE: 'voice-call',
  VIDEO: 'video-call',
};

const SUBSCRIPTION_TYPES = {
  monthly: 'monthly',
  quarterly: 'quarterly',
  yearly: 'yearly',
};
const SUBSCRIPTION_STATUS = {
  paid: 'active',
  canceled: 'canceled',
};
const INVOICE_STATUS = {
  pending: 'pending',
  paid: 'paid',
};
const NOTIFICATION_TYPES = {
  notification: 'notification',
  chat: 'chat',
};
const REACT_APP_URL = process.env.REACT_APP_URL || process.env.REACT_APP_API_URL;
const SEND_TYPE = {
  pdf: 'PDF',
  link: 'Link',
};
const CHAT_MESSAGE_STATUS = {
  read: 'message_read',
  deliver: 'message_delivered',
};
const CHAT_ACTIVITY_STATUS = {
  online: {
    title: 'online',
    color: '#4CBB17',
  },
  offline: {
    title: 'offline',
    color: 'gray',
  },
  meeting: {
    title: 'meeting',
    color: 'blue',
  },
};
export {
  SUBSCRIPTION_STATUS,
  CHAT_ACTIVITY_STATUS,
  CHAT_MESSAGE_STATUS,
  NOTIFICATION_TYPES,
  SUBSCRIPTION_TYPES,
  INVOICE_STATUS,
  SEND_TYPE,
  REACT_APP_URL,
  OTP_FAIL_ERROR,
  ACCOUNT_PENDING_ERROR,
  googleCalendarToken,
  microsoftCalendarToken,
  MEETING_TYPES,
  COACHES_DOMAIN
};
