const jwtServiceConfig = {
  signIn: 'token',
  signUp: {
    agent: 'agent/register',
    entrepreneur: 'entrepreneur/register',
  },
  accessToken: 'token/test',
  updateUser: 'users/profile',
  getUser: 'users',
  getCurrentUser: 'users/my',
  sso: {
    google: 'login/google',
    graph: 'login/graph',
  },
};
const USER_TOKEN="userData"
const USER_ROLE="userRole"
export  {
  USER_TOKEN,
  USER_ROLE
}

export default jwtServiceConfig;
