import axios from 'axios';
import axiosWithSnackbar from "src/app/core/FuseAuthorization/axiosWithSnackbar";

export const bookAppointmentApi = async (todo) => {
  return await axiosWithSnackbar.post('/appointments', todo);
};

export const updateBookAppointmentApi = async (todo, id) => {
  return await axiosWithSnackbar.patch(`/appointments/${id}`, todo);
};

export const fetchBookedAppointments = () => {
  return axios.get('/appointments/my');
};

export const fetchBookedAppointmentByToken = (appointmentToken) => {
  return axios.get(`/appointments/token/${appointmentToken}`);
};

export const postAppointmentFeedback = (encriptedAppointmentToken) => {
  return axiosWithSnackbar.post('/appointments/thumbup', {
    token: encriptedAppointmentToken,
  });
};
