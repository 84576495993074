import React, { memo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCoin } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";

import useHeaderHook from "./hooks/useHeaderHook";
import ImageWithFallback from "../UI/ImageWithFallback";
import { useSelector } from "react-redux";

const HeaderComponent = (props) => {
  const userType = useSelector(
    (state) => state.userReducer.userDetails?.todo?.data?.role
  );

  const { values } = useHeaderHook({ userType });

  if (!values) {
    return <></>;
  }
  const {
    getHeaderImage,
    getHeaderCoins,
    toTokenView,
    getProfileRoute,
    getSearchRoute,
  } = values;

  return (
    <div className="headerComponent h-[73px] bg-white relative z-10 w-full px-[20px] flex justify-between items-center">
      {props.isBarHidden ? null : (
        <button className="menuBarsIcon mr-10" onClick={props.toggleClick}>
          <FontAwesomeIcon
            icon={faBars}
            className="text-[20px] text-darkColor"
          />
        </button>
      )}

      {getSearchRoute ? (
        <div className="searchContainer ml-0 mr-1 flex-1">
          <Link
            to={getSearchRoute}
            className=" flex justify-start items-center "
          >
            <FontAwesomeIcon
              icon={faSearch}
              className="text-[15px] text-iconsLightGreyColor mr-3"
            />
            <p className="text-[14px] text-darkColor">Search</p>
          </Link>
        </div>
      ) : (
        <div className="searchContainer ml-0 mr-1 flex-1"></div>
      )}
      {toTokenView && (
        <div
          className="coinsContainer flex justify-start items-center border-dividerColor  border-r-[1px] h-[34px] pr-3 mr-3 cursor-pointer"
          onClick={toTokenView}
        >
          <FontAwesomeIcon
            icon={faCoin}
            className="text-[15px] text-darkColor mr-2 cursor-pointer"
          />
          <p className="text-[13px] text-greenColor font-bold">
            {getHeaderCoins()}
          </p>
        </div>
      )}
      <div className="profileContainer mr-0 flex justify-start items-center">
        {/* <div className="relative">
          <FontAwesomeIcon
            icon={faBell}
            className="text-[15px] text-iconsLightGreyColor"
          />
          <div className="absolute w-[12px] h-[12px] rounded-[12px] text-[6px] bg-red-500 text-white text-center leading-[12px] top-0 right-[-6px]">
            10
          </div>
        </div> */}

        <div className="ml-0 h-[24px] w-[24px] rounded-[24px] overflow-hidden">
          <Link to={getProfileRoute}>
            <ImageWithFallback
              className="object-cover w-full h-full"
              src={getHeaderImage()}
              alt="open profile page"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

HeaderComponent.propTypes = {
  toggleClick: PropTypes.func.isRequired,
};

export default memo(HeaderComponent);
