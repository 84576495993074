import { memo } from "react";
import { ToastContainer } from "react-toastify";

const AppPlugins = () => (
  <>
    {/* Add here more application wide plugins */}
    <ToastContainer />
  </>
);

export default memo(AppPlugins);
