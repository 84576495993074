import { useCallback } from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  faHome,
  faCoins,
  faSearch,
  faCalendar,
  faListDots,
  faTools,
  faChartBar,
  faCalendarAlt,
  faBuilding,
  faUserAlt,
  faBoxesStacked,
} from "@fortawesome/pro-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import {
  faRightFromBracket,
  faUser,
  faWatch,
} from "@fortawesome/pro-solid-svg-icons";
import { faCalendarNote } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useRequestHook from "src/app/views/hooks/userHooks/useRequestHook";
import { useSelector } from "react-redux";
import useCheckMobileScreen from "src/app/views/hooks/generalHooks/useCheckMobileScreen";

const useSideNavHook = (props) => {
  const userType = useSelector(
    (state) => state.userReducer.userDetails?.todo?.data?.role
  );
  const isMobile = useCheckMobileScreen();
  const { pathname } = useLocation();
  const { onLogout } = useRequestHook();

  const [initial, setInitial] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (props.open) {
      timeout = setTimeout(() => {
        setInitial(true);
      }, 200);
    } else {
      setInitial(false);
    }
    return () => {
      clearTimeout(timeout);
      setInitial(false);
    };
  }, [props.open]);

  const closeUi = useCallback(
    (e) => {
      setInitial(false);
      const timeout = setTimeout(() => {
        props.onClose(e);
      }, 200);
      return () => {
        clearTimeout(timeout);
        setInitial(false);
      };
    },
    [props]
  );
  const mobileClose = () => {
    return isMobile ? props.onClose() : null;
  };
  const GetSideNavComponent = useCallback(() => {
    const Nav = {
      "Company Admin": (
        <>
          <nav id="navigation" onClick={mobileClose} className="w-full flex-1">
            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="mr-4 text-primaryColor"
                />
                Dashboard
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname.includes("/company/departments")
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/company/departments"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faBoxesStacked}
                  className="mr-4 text-primaryColor"
                />
                Departments
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company/employees"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/company/employees"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faUserAlt}
                  className="mr-4 text-primaryColor"
                />
                Account Users
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company/reports" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/company/reports"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faChartBar}
                  className="mr-4 text-primaryColor"
                />
                Reports
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company/resources"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/company/resources"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faTools}
                  className="mr-4 text-primaryColor"
                />
                Resources
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company/edit" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/company/edit"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faBuilding}
                  className="mr-4 text-primaryColor"
                />
                Company Edit
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company/token/schedule"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/company/token/schedule"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="mr-4 text-primaryColor"
                />
                Token Schedule
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/company/transaction"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/company/transaction"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCoins}
                  className="mr-4 text-primaryColor"
                />
                Transaction
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md`}
            >
              <button
                onClick={onLogout}
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="mr-4 text-primaryColor"
                />
                Logout
              </button>
            </div>
          </nav>
        </>
      ),
      coaches: (
        <>
          <nav id="navigation" onClick={mobileClose} className="w-full flex-1">
            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/coaches" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/coaches"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="mr-4 text-primaryColor"
                />
                Dashboard
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/coaches/profile" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/coaches/profile"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className="mr-4 text-primaryColor"
                />
                Your Profile
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/coaches/availability"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/coaches/availability"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faWatch}
                  className="mr-4 text-primaryColor"
                />
                Availability
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/coaches/sessions" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/coaches/sessions"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCalendarNote}
                  className="mr-4 text-primaryColor"
                />
                Coaching Sessions
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/coaches/transaction"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/coaches/transaction"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCoins}
                  className="mr-4 text-primaryColor"
                />
                Transaction History
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md`}
            >
              <button
                onClick={onLogout}
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="mr-4 text-primaryColor"
                />
                Logout
              </button>
            </div>
          </nav>
        </>
      ),
      Employee: (
        <>
          <nav id="navigation" onClick={mobileClose} className="w-full flex-1">
            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/employee" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/employee"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="mr-4 text-primaryColor"
                />
                Dashboard
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/employee/token" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/employee/token"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCoins}
                  className="mr-4 text-primaryColor"
                />
                Tokens
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/employee/transaction"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/employee/transaction"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCoins}
                  className="mr-4 text-primaryColor"
                />
                Transaction
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/employee/search" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/employee/search"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-4 text-primaryColor"
                />
                Search for a Coach
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/employee/sessions"
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/employee/sessions"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="mr-4 text-primaryColor"
                />
                Coaching Sessions
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname.includes("/employee/profile/edit")
                  ? "bg-white bg-opacity-10"
                  : ""
              }`}
            >
              <Link
                to="/employee/profile/edit"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faListDots}
                  className="mr-4 text-primaryColor"
                />
                Edit Profile
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md ${
                pathname === "/employee/tools" ? "bg-white bg-opacity-10" : ""
              }`}
            >
              <Link
                to="/employee/tools"
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faTools}
                  className="mr-4 text-primaryColor"
                />
                Useful Tools
              </Link>
            </div>

            <div
              className={`w-full h-[39px] flex justify-start items-center p-2 rounded-md`}
            >
              <button
                onClick={onLogout}
                className="block text-white sm:text-[14px] text-[18px]"
              >
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="mr-4 text-primaryColor"
                />
                Logout
              </button>
            </div>
          </nav>
        </>
      ),
    };

    return Nav[userType];
  }, [onLogout, userType, pathname]);

  return {
    closeUi,
    initial,
    GetSideNavComponent,
  };
};

useSideNavHook.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default useSideNavHook;
