export default function employeeCheckoutReducer(
  state = {
    checkoutInit: {
      initial: true,
      pending: true,
      failed: false,
      message: "Pending...",
      todo: {
        data: null,
        error: null,
      },
    },
  },
  action
) {
  switch (action.type) {
    case "TOGGLE_CHECKOUT": {
      return {
        ...state,
        checkoutInit: action.data,
      };
    }
    case "STRIP_SECRET": {
      return {
        ...state,
        paymentIntent: action.data,
      };
    }
    default:
      return state;
  }
}
