// Action Types

import { HIDE_STATUS, SHOW_STATUS } from "../actionTypes/snackbarActionTypes";

// Action Creators
export const showMessage = payload => ({
  type: SHOW_STATUS,
  payload,
});

export const hideMessage = () => ({
  type: HIDE_STATUS,
});
