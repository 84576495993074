import { useContext, useEffect } from "react";
import LayoutContext from "src/app/context/LayoutContext";

const useSetLayoutHook = (newState) => {
    const { updateLayoutState } = useContext(LayoutContext);
    useEffect(() => {
        updateLayoutState(newState);
        return () => updateLayoutState({ });
    }, [newState, updateLayoutState]);
  };
  export default useSetLayoutHook