import { lazy } from "react";
import authRoles from "../authRoles";
import Initialize from "src/app/views/Initialize";

const EmployeeDashboardView = lazy(() =>
  import("src/app/views/employee/EmployeeDashboardView")
);
const EmployeeTokenView = lazy(() =>
  import("src/app/views/employee/EmployeeTokenView")
);
const EmployeeTokenRequestView = lazy(() =>
  import("src/app/views/employee/EmployeeTokenRequestView")
);
const EmployeeCheckoutView = lazy(() =>
  import("src/app/views/employee/EmployeeCheckoutView")
);
const EmployeeToolsView = lazy(() =>
  import("src/app/views/employee/EmployeeToolsView")
);
const EmployeeAssessmentView = lazy(() =>
  import("src/app/views/employee/EmployeeAssessmentView")
);
const AssessmentQueryView = lazy(() =>
  import("src/app/views/employee/assessments/AssessmentQueryView")
);
const EmployeeEditProfile = lazy(() =>
  import("src/app/views/employee/EmployeeEditProfile")
);
const EmployeeSearchView = lazy(() =>
  import("src/app/views/employee/EmployeeSearchView")
);
const EmployeeSearchCoachDetailView = lazy(() =>
  import("src/app/views/employee/EmployeeSearchCoachDetailView")
);
const EmployeeSessionsView = lazy(() =>
  import("src/app/views/employee/EmployeeSessionsView")
);
const EmployeeSessionsDetailView = lazy(() =>
  import("src/app/views/employee/EmployeeSessionsDetailView")
);
const EmployeeTransactionView = lazy(() =>
  import("src/app/views/employee/EmployeeTransactionView")
);
const EmployeeAppointmentFeedback = lazy(() =>
  import("src/app/views/employee/EmployeeAppointmentFeedback")
);
const EmployeeEditProfileStep = lazy(() =>
  import("src/app/views/employee/EmployeeEditProfile/EmployeeEditProfileStep")
);

const EmployeeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.Employee,
  routes: [
    {
      path: "employee",
      element: <Initialize />,
      children: [
        {
          path: "",
          element: <EmployeeDashboardView />,
        },
        {
          path: "token",
          children: [
            {
              path: "",
              element: <EmployeeTokenView />,
            },
            {
              path: "request",
              element: <EmployeeTokenRequestView />,
            },
            {
              path: "checkout",
              element: <EmployeeCheckoutView />,
            },
          ],
        },
        {
          path: "tools",
          element: <EmployeeToolsView />,
        },
        {
          path: "assessment",
          children: [
            {
              path: "",
              element: <EmployeeAssessmentView />,
            },
            {
              path: ":step",
              element: <AssessmentQueryView />,
            },
          ],
        },
        {
          path: "profile/edit",
          element: <EmployeeEditProfile />,
          children: [
            {
              path: ":step",
              element: <EmployeeEditProfileStep />,
            },
          ],
        },
        {
          path: "search",
          children: [
            {
              path: "",
              element: <EmployeeSearchView />,
            },
            {
              path: "coach",
              element: <EmployeeSearchCoachDetailView />,
            },
          ],
        },
        {
          path: "sessions",
          children: [
            {
              path: "",
              element: <EmployeeSessionsView />,
            },
            {
              path: "details",
              element: <EmployeeSessionsDetailView />,
            },
          ],
        },
        {
          path: "transaction",
          element: <EmployeeTransactionView />,
        },
      ],
    },
    {
      path: "feedback",
      element: <EmployeeAppointmentFeedback />,
    },
  ],
};

export default EmployeeConfig;
