export const users = {
  Employee: "Employee",
  coaches: "coaches",
  "Company Admin": "Company Admin",
};
export const userRedirects = {
  Employee: "/employee",
  coaches: "/coaches",
  "Company Admin": "/company",
};

const { Employee, coaches, "Company Admin": companyAdmin } = users;
const authRoles = {
  Employee: [Employee],
  coaches: [coaches],
  companyAdmin: [companyAdmin],
};
const isUser = (userType, user) => {
  return userType.includes(user?.role);
};
export const userType = {
  isEmployee: (user) => isUser(authRoles.Employee, user),
  isCoach: (user) => user && !user?.role,
  isCompanyAdmin: (user) => isUser(authRoles.companyAdmin, user),
};

export default authRoles;
